import React, { useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import CredData from "./CredData"
import CredForm from "./CredForm"
import CredAudit from "./CredAudit"

function CredDetails({ apptSys, onCancel }) {
  const [showEdit, setShowEdit] = useState(false)

  const toggleEdit = () => {
    setShowEdit(!showEdit)
  }


  return (
    <Row>
        {!showEdit &&<Col md={8}>
            <CredData apptSys={apptSys} onCancel={onCancel} onEdit={toggleEdit} />
        </Col>}
        {showEdit && <Col>
            <CredForm onCancel={toggleEdit} isEdit={true} appt_sys={apptSys}/>
        </Col>}
        <Col md={4}>
        {!showEdit && <CredAudit appt_sys={apptSys}/>}
        </Col>
    </Row>
  )
}

export default CredDetails
