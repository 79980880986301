import React, { useEffect, useState } from "react"

import classes from "./Timeline.scss"
import { Button, Card, CardBody, Modal } from "reactstrap"
import moment from "moment"
import ImageModal from "./ImageModal"
import Success from "pages/NotificationModal/Success"
import LoadingOverlay from "react-loading-overlay"
import OpenDockDetails from "./OpenDockDetails"

const Timeline = props => {
  let row = { ...props.data }
  const [loading, setLoading] = useState(true)
  const [emailLoading, setEmailLoading] = useState(true)
  const [modal_center, setmodal_center] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [emailcontent, setEmailContent] = useState("")
  const [showFooter, setShowFooter] = useState(false)
  const [refs, setRefs] = useState(null)
  const [imageLoading, setImageLoading] = useState(true)
  const [confirmDate, setConfirmDate] = useState(null)
  const [confirmTime, setConfirmTime] = useState(null)
  const [showSuccessModal, setShowsuccessModal] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState(null)
  const [openDockData, setOpenDockData] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  const parseDate = datestr => {
    let date = moment(datestr).format("MM-DD-yyyy @ hh:mm A")
    return date
  }

  const handleMoreDetails = status => {
    console.log("status : " + status)
  }

  const cleanupEmailContent = () => {
    setEmailContent("")
    setConfirmDate(null)
    setConfirmTime(null)
  }

  function tog_center(status) {
    loadEmailContent(status)
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const togImageModal = async status => {
    debugger
    setImageLoading(true)
    setShowImageModal(!showImageModal)
    var refs = []
    if (status && status.reference_fields) {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      var fetchArray = []
      status.reference_fields.map(item => {
        fetchArray.push(
          fetch(
            api_url +
              "stop/timeline/" +
              row.original.stopId +
              "/" +
              item.type +
              "/" +
              item.key,
            { headers }
          )
        )
      })

      Promise.all(fetchArray).then(res => {
        Promise.all(
          res.map(item => {
            return item.text()
          })
        ).then(data => {
          data.map((item, index) => {
            refs.push({ name: status.reference_fields[index].name, img: item })
          })
          setRefs(refs)
          setImageLoading(false)
        })
      })
    }
  }

  const togDetailsModal = (openDockData) => {
    setShowDetailsModal(!showDetailsModal)
    if (!showDetailsModal)
      setOpenDockData(openDockData)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [data, setData] = useState([])
  useEffect(() => {
    fetchData(row)
  }, [])

  const closeOpenDockModal = () => {
    setShowDetailsModal(false)
    setOpenDockData(null)
  }

  const fetchData = async row => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url + "stop/timeline/" + row.original.stopId,
        { headers }
      ) // Replace with your API endpoint

      if (!response.ok) {
        debugger
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const loadEmailContent = async status => {
    try {
      setEmailLoading(true)
      setShowFooter(
        status.attributes && status.attributes.manual_emailconf_review_rq
      )
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url +
          "stop/timeline/" +
          row.original.stopId +
          "/" +
          "email/" +
          status.attributes.email_content_id,
        { headers }
      ) // Replace with your API endpoint

      if (!response.ok) {
        throw new Error("Request failed")
      }

      let data = await response.text()
      setEmailContent(data)
      setEmailLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setEmailLoading(false)
    }
  }

  const closeImageModal = () => {
    setShowImageModal(false)
  }

  const markConfirmed = async data => {
    console.log(data)
    setSaving(true)
    try {
      const token = localStorage.getItem("idToken")

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stopId: row.original.stopId,
          appointmentDate: confirmDate,
          appointmentTime: confirmTime,
          refAuditId: data.audit_id,
        }),
      }

      const response = await fetch(api_url + "appointment/confirm", config)

      if (!response.ok) {
        var error = await response.json()
        setError(error.message)
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      setSaving(false)
      setmodal_center(false)
      setShowsuccessModal(true)
      cleanupEmailContent()
      //setData(jsonData)
    } catch (error) {
      console.error("Error:", error)
      setSaving(false)
    }
  }

  const handleDateTimeChange = event => {
    var datetime = event.target.value
    console.log(datetime)
    var datetimearr = datetime.split("T")
    setConfirmDate(datetimearr[0])
    var time = datetimearr[1].split(":")[0] + datetimearr[1].split(":")[1]
    setConfirmTime(time)
    console.log(confirmDate)
    console.log(confirmTime)
    console.log(row)
  }

  const closeSuccessModal = () => {
    setShowsuccessModal(false)
    fetchData(row)
  }

  return (
    <>
      <Modal
        isOpen={showSuccessModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="sm"
      >
        <Success
          message="Appointment confirmed successfully !!"
          close={closeSuccessModal}
        />
      </Modal>
      <Modal
        isOpen={showDetailsModal}
        toggle={() => {
          togDetailsModal()
        }}
        centered
        size="lg"
      >
        <OpenDockDetails
          closeOpenDockModal={closeOpenDockModal}
          stop={row.original}
          data = {openDockData}

        />
      </Modal>
      {loading && <div>Loading....</div>}
      {!loading && data.length === 0 && <div>No time line data available.</div>}
      {!loading && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="w-100 mt-3">
            <ul className="verti-timeline list-unstyled">
              {data.map((status, key) => (
                <li key={key} className="event-list m-0 pb-1 pl-20">
                  <div className="event-timeline-dot">
                    <i
                      className={`bx bx-right-arrow-circle text-${
                        status.title.toLowerCase().includes("failed")
                          ? "red"
                          : status.title.includes("confirmed")
                          ? "success"
                          : "primary"
                      }`}
                    />
                  </div>
                  <div className="d-flex">
                    <div className="me-3">
                      <i
                        className={
                          "bx " +
                          status.iconClass +
                          `" h2 text-${
                            status.title.toLowerCase().includes("failed")
                              ? "red"
                              : status.title.toLowerCase().includes("confirmed")
                              ? "success"
                              : "primary"
                          }"`
                        }
                      />
                    </div>
                    <div className="flex-grow-1">
                      <div>
                        <h6
                          className={`text-${
                            status.title.toLowerCase().includes("failed")
                              ? "red"
                              : status.title.toLowerCase().includes("confirmed")
                              ? "success"
                              : "primary"
                          }`}
                        >
                          {status.title}
                        </h6>
                        <p className="text-muted mb-0">
                          {parseDate(status.created_at)}
                        </p>
                        {!status.moredetails && (
                          <p className="text-muted mb-0">{status.details}</p>
                        )}
                        {status.attributes &&
                          status.attributes.email_content_id && (
                            <Button
                              color="primary"
                              className="btn-sm m-1"
                              outline
                              onClick={() => {
                                tog_center(status)
                              }}
                            >
                              See Email
                            </Button>
                          )}

                        {status.reference_fields &&
                          status.reference_fields.length > 0 &&
                          status.reference_fields[0]["content-type"] ==
                            "png" && (
                            <Button
                              color="primary"
                              className="btn-sm m-1"
                              outline
                              onClick={() => {
                                togImageModal(status)
                              }}
                            >
                              See Screens
                            </Button>
                          )}

                        {status.reference_fields &&
                          status.reference_fields.length > 0 &&
                          status.reference_fields[0]["content-type"] ==
                            "confirmation" &&
                          status.reference_fields[0]["type"] == "OPENDOCK" && (
                            <Button
                              color="primary"
                              className="btn-sm m-1"
                              outline
                              onClick={() => {
                                togDetailsModal(status.reference_fields[0])
                              }}
                            >
                              See Details
                            </Button>
                          )}

                        {/* Modal content*/}
                        <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center(null)
                          }}
                          centered
                          size="lg"
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">Email Content</h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_center(false)
                                cleanupEmailContent()
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={cleanupEmailContent}
                              >
                                &times;
                              </span>
                            </button>
                          </div>
                          {emailLoading && (
                            <div className="modal-body">Loading...</div>
                          )}
                          {!emailLoading && (
                            <div
                              className="modal-body"
                              dangerouslySetInnerHTML={{
                                __html: emailcontent,
                              }}
                            ></div>
                          )}

                          {!emailLoading && showFooter && (
                            <LoadingOverlay active={saving} spinner>
                              <div className="modal-footer">
                                <label
                                  htmlFor="example-datetime-local-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Date and time
                                </label>
                                <div className="col-md-6">
                                  <input
                                    className="form-control"
                                    type="datetime-local"
                                    defaultValue=""
                                    id="example-datetime-local-input"
                                    onChange={handleDateTimeChange}
                                  />
                                </div>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn btn-sm waves-effect waves-light btn-primary"
                                  id="confirm"
                                  disabled={!confirmDate}
                                  onClick={() => {
                                    markConfirmed(status)
                                  }}
                                >
                                  Mark Confirmed
                                </Button>
                                <Button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                  id="cancel"
                                  onClick={() => {
                                    setmodal_center(false)
                                    cleanupEmailContent()
                                  }}
                                >
                                  Cancel
                                </Button>
                                {error && (
                                  <div className="text-danger text-float-left">
                                    {error}
                                  </div>
                                )}
                              </div>
                            </LoadingOverlay>
                          )}
                        </Modal>
                        <Modal
                          isOpen={showImageModal}
                          toggle={() => {
                            togImageModal(null)
                          }}
                          centered
                          size="xl"
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">
                              Confirmation Screens
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                closeImageModal()
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {imageLoading && (
                            <div className="modal-body">Loading..</div>
                          )}
                          {!imageLoading && <ImageModal refs={refs} />}
                        </Modal>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Timeline
