import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle, Col, Container } from "reactstrap"
import ViewNotification from "./ViewNotification"
import EditNotification from "./EditNotification"

const Notification = () => {
  document.title = "Notification Management | Qued Portal"
  const [view, setView] = useState(true)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem("idToken")
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const response = await fetch(api_url + "notification", { headers })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      setData(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const showEdit = () => {
    setView(false)
    setIsNew(false)
  }

  const cancelEdit = () => {
    setView(true)
    loadData()
  }

  const setNewForm = () => {
    setIsNew(true)
    setView(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Card>
            <CardBody>
              <Col>
                <div className="float-start">
                  <h5 className="text-primary">Notification Management</h5>
                </div>
              </Col>
              <Col>
                <div className="float-end">
                  <div className="d-flex gap-2">
                    <div className="text-sm">
                     

                      <span
                        onClick={() => {
                          loadData()
                        }}
                        className="pointer text-primary"
                      >
                        <i className="fas fa-sync-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {loading && <div className="p-4">Loading..</div>}
              {!loading && (
                <div>
                  {view && <ViewNotification showEdit={showEdit} data={data} setNewForm={setNewForm}/>}
                  {!view && (
                    <EditNotification data={data} cancelEdit={cancelEdit} isNew={isNew}/>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notification
