import ClosableTag from "pages/Notification/ClosableTag";
import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";

const ViewAddresses = (props) => {

    const [data, setData] = useState(props.data)
    const [tos, setTos] = useState([])
    const [ccs, setCcs] = useState([])

    useEffect(() => {
        
        setTimeout(() => {
          console.log(props.data)
          setData(props.data)
          setTos(props.data.to?.split(','))
          if (props.data.cc?.length>1){
            setCcs(props.data?.cc.split(','))
          }
          
        }, 1000)
      }, [])

      const edit = () => {
        props.edit()
      }

    return (
        <>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">TO</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {tos?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">CC</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {ccs?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <div className="modal-footer">
                    <Button color="primary" className='btn btn-primary' onClick={edit}>Edit</Button>
          </div>
          </>
    )
}

export default ViewAddresses