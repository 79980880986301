import React, { useMemo, useState, useRef } from "react"
import { stops } from "../../common/data/stops"
import { useNavigate } from "react-router-dom"
import Pool from "UserPool";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr"

import { Box } from "@mui/material"
import classes from "./orderdashboard.scss"
import { useEffect } from "react"
import Temp from "./Temp"
import OrderWidget from "./OrderWidget"
import QuedTableContainer from "components/Common/QuedTableContainer"
import AdvancedFilter from "./AdvancedFilter"
import moment from "moment"
import ClippedText from "pages/ClippedText/ClippedText"
import DetailReference from "pages/ClippedText/DetailsReference"

const data = stops

var showMoreFlag = false

const tablePaperProps = {
  elevation: 0,
}

const clickMe = () => {
  alert("Resubmitting")
}

const getRow = renderedCellValue => {
  return renderedCellValue.toString().split(",").join("\n")
}

const shouldShowMore = ({ row }) => {
  row.original.references.map(i => {
    if (i.length > 50) {
      return true
    }
  })
  return false
}

const getColumns = () => {
  return useMemo(
    () => [
      {
        accessor: "quedId", //access nested data with dot notation
        Header: "Qued #",
        width: 80,
      },
      {
        accessor: "orderNumber", //access nested data with dot notation
        Header: "Order #",
        width: 80,
      },
      {
        accessor: "stopType",
        Header: "Stop Type",
        width: 40,
      },
      {
        accessor: "references",
        Header: "References",
        Cell: ({ row }) => (
          <Box style={{ width: "100%" }}>
            {row.original.references &&
              row.original.references.map((i, index) => (
                <ClippedText key={index} maxLength={30} text={i} />
              ))}
            {row.original.references.filter(i => i.length > 30).length > 0 && (
              <DetailReference fulltext={row.original.references} />
            )}
          </Box>
        ),
        width: 100,
      },
      {
        accessor: "tmsLocationId", //normal accessor
        Header: "Location Id",
        width: 80,
      },
      {
        accessor: "tmsLocationName",
        Header: "Location Name",
        width: 100,
      },
      {
        accessor: "apppointmentStatus",
        Header: "Status",
        width: 100,
        Cell: ({ row }) => (
          <Box>
            {row.original.apppointmentStatus === "Confirmed" ? (
              <Badge className="font-size-10 badge-soft-success">
                {row.original.apppointmentStatus}
              </Badge>
            ) : row.original.apppointmentStatus === "New Request" ? (
              <Badge className="font-size-10 badge-soft-primary">
                {row.original.apppointmentStatus}
              </Badge>
            ) : row.original.apppointmentStatus === "Submitted" ? (
              <Badge className="font-size-10 badge-soft-warning">
                {row.original.apppointmentStatus}
              </Badge>
            ) : row.original.apppointmentStatus === "Waiting For Approval" ? (
              <Badge className="font-size-10 badge-soft-warning">
                {row.original.apppointmentStatus}
              </Badge>
            ) : (
              <Badge className="font-size-10 badge-soft-danger">
                {row.original.apppointmentStatus}
              </Badge>
              )}
            <div className="text-danger">
            {row.original.apppointmentStatus == 'Exception' &&<ClippedText maxLength={30} text={row.original.exceptionReason} />}
            </div>
          </Box>
        ),
      },
      {
        accessor: "tmsRequestedAppointmentDate",
        Header: "Requested Date",
        width: 80,
        maxWidth: 80,
        style: {
          textAlign: "center",
        },
      },
      {
        accessor: "scheduledAppointmentDate",
        Header: "Scheduled Date",

        Cell: ({ row }) => (
          <Box>
            {row.original.scheduledAppointmentDate && (
              <div>
                <span>{row.original.scheduledAppointmentDate}</span>
                <span>
                  {" @ "}
                  {row.original.scheduledAppointmentTime}
                </span>
              </div>
            )}
          </Box>
        ),
        width: 60,
        maxWidth: 80,
        style: {
          textAlign: "center",
        },
      },
    ],
    []
  )
}

const OrderDashboard3 = () => {
  const [stops, setStops] = useState([])
  const [originalStops, setOriginalStops] = useState([])
  const [requiredStops, setRequiredStops] = useState([])
  const [submittedStops, setSubmittedStops] = useState([])
  const [confirmedStops, setConfirmedStops] = useState([])
  const [exceptionStops, setExceptionStops] = useState([])
  const [counters, setCounters] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [queryParam, setQueryParam] = useState(null)
  const [customStartDate, setCustomStartDate] = useState(null)
  const [customEndDate, setCustomEndDate] = useState(null)
  const [showMore, setShowMore] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [defaultSearch, setDefaultSearch] = useState('allFuture')


  const api_url = process.env.REACT_APP_API_URL

  const apiUrl = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const orderWidgetRef = useRef()

  const toggleAdvancedFilter = () => {
    setShowAdvancedFilter(!showAdvancedFilter)
  }

  const search = ({ obj }) => {
    console.log(obj)
  }



  useEffect(() => {
    fetchData("allFuture")
    let interval = setInterval(() => {
      fetchData("allFuture")
    }, 30 * 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const searchCustom = () => {
    tog_center(true)
  }

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    } 
    localStorage.removeItem("email");
    localStorage.removeItem("idToken")
    navigate("/");
  }

  const refreshTable = () => {
    if (defaultSearch === 'custom') {
      fetchData(defaultSearch, startDate, endDate);
    } else {
      fetchData(defaultSearch)
    }
  }

  const fetchData = async (duration, startDate, endDate) => {
    let fromDateStr = null
    let toDateStr = null

    if (duration === "month") {
      var d = new Date()
      var fromDate = new Date()
      var toDate = d.setDate(d.getDate() + 30)

      fromDateStr = moment(fromDate).format("yyyy-MM-DD")
      toDateStr = moment(toDate).format("yyyy-MM-DD")
      orderWidgetRef.current.resetActive()
    } else if (duration === "today") {
      fromDateStr = moment(new Date()).format("yyyy-MM-DD")
      toDateStr = moment(new Date()).format("yyyy-MM-DD")
      orderWidgetRef.current.resetActive()
    } else if (duration === "week") {
      var currentDate = new Date()
      fromDateStr = moment(currentDate).format("yyyy-MM-DD")
      var toDate = currentDate.setDate(currentDate.getDate() + 7)
      toDateStr = moment(toDate).format("yyyy-MM-DD")
      orderWidgetRef.current.resetActive()
    } else {
      fromDateStr = startDate
      toDateStr = endDate
      orderWidgetRef.current.resetActive()
    }

    setIsLoading(true)

    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      if (duration != "allFuture") {
        url = url + "stop?fromDate=" + fromDateStr + "&toDate=" + toDateStr
      } else {
        url = url + "stop"
      }

      const response = await fetch(url, { headers })

      if (!response.ok) {

        if ([401, 403].includes(response.status)) {
           logout();
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setStops(jsonData.content)
      setQueryParam(jsonData.queryParams)
      setOriginalStops(jsonData.content)
      setCounters(jsonData.counters)
      setIsLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setIsLoading(false)
      console.error(error)
      //navigate("/")
    }
  }

  const searchToday = () => {
    setActiveFilter("allFuture")
    fetchData("allFuture")
  }

  const searchMonth = () => {
    setActiveFilter("month")
    fetchData("month")
  }

  const searchWeek = () => {
    setActiveFilter("week")
    fetchData("week")
  }

  const expandRow = (state, rowInfo, column, instance) => {
    rowInfo.isExpand ? (rowInfo.isExpand = false) : (rowInfo.isExpand = true)
  }

  const columns = getColumns()
  const [settingsMenu, setSettingsMenu] = useState(false)

  const setActiveFilter = (duration) => {
    setDefaultSearch(duration)
  }

  const filterRequired = () => {
    let requiredStops = originalStops.filter(i => {
      return i.apppointmentStatus === "New Request"
    })
    setRequiredStops(requiredStops)
    setStops(requiredStops)
  }

  const filterAll = () => {
    setStops(originalStops)
  }

  const filterSubmitted = () => {
    let requiredStops = originalStops.filter(i => {
      return i.apppointmentStatus === "Submitted"
    })
    setSubmittedStops(requiredStops)
    setStops(requiredStops)
  }

  const filterException = () => {
    let requiredStops = originalStops.filter(i => {
      return i.apppointmentStatus === "Exception"
    })
    setExceptionStops(requiredStops)
    setStops(requiredStops)
  }

  const filterAwaitingApprovals = () => {
    let requiredStops = originalStops.filter(i => {
      return i.apppointmentStatus === "Waiting For Approval"
    })
    //(requiredStops)
    setStops(requiredStops)
  }

  const filterConfirmed = () => {
    let requiredStops = originalStops.filter(i => {
      return i.apppointmentStatus === "Confirmed"
    })
    setConfirmedStops(requiredStops)
    setStops(requiredStops)
  }

  const filterDefault = () => {
    setStops(originalStops)
  }

  const clearSearch = () => {
    setShowAdvancedFilter(false)
    fetchData("week")
  }

  function tog_center(status) {
    setmodal_center(!modal_center)
  }

  const handleFromDate = e => {
    var d = new Date(e)
    var fromDateStr = moment(d).format("yyyy-MM-DD")
    setStartDate(fromDateStr)
  }


  const handleToDate = e => {
    var d = new Date(e)
    var toDateStr = moment(d).format("yyyy-MM-DD")
    setEndDate(toDateStr)
  }

  const searchCustomDateRange = () => {
    setActiveFilter("custom")
    setmodal_center(false)
    fetchData("custom", startDate, endDate)
  }

  const incrementConfirm = ({row}) => {
    counters.CONFIRMED = counters.CONFIRMED + 1;
    counters.EXCEPTION = counters.EXCEPTION - 1;
    renderRowSubComponent(row)
    
  }

  const renderRowSubComponent = React.useCallback(({ row }) => (
    <Temp data={row} refreshTable={refreshTable} incrementConfirm={incrementConfirm} />
  ))

  document.title = "Scheduling | Qued Portal"

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center(null)
          }}
          centered
          size="sm"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Date Range</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setmodal_center(false)}>
                &times;
              </span>
            </button>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              searchCustomDateRange()
            }}
          >
            <div className="modal-body">
            <div className="form-group mb-4">
                <Label>From Date</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d"
                    }}
                    onChange={handleFromDate}
                    value={fromDate}
                  />
                </InputGroup>
              </div>
              <div className="form-group mb-4">
                <Label>To Date</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d"
                    }}
                    onChange={handleToDate}
                    value={toDate}
                  />
                </InputGroup>
              </div>
              
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Ok
              </button>
            </div>
          </Form>
        </Modal>
        <Container fluid>
          <Row>
            <Col xl="12">
              {/**  widgets starts here*/}
              <Row style={{ justifyContent: "space-between" }}>
                {/*mimi widgets */}
                <OrderWidget
                  counters={counters}
                  filterRequired={filterRequired}
                  filterSubmitted={filterSubmitted}
                  filterException={filterException}
                  filterConfirmed={filterConfirmed}
                  filterDefault={filterDefault}
                  filterAwaitingApprovals={filterAwaitingApprovals}
                  filterAll={filterAll}
                  ref={orderWidgetRef}
                />
              </Row>
              {/**  widgets ends here*/}
            </Col>
          </Row>
          {!showAdvancedFilter && (
            <Row>
              <Col xl="12">
                <div className="text-sm-end mb-2">
                  <span
                    className="text-primary mb-3 pointer"
                    onClick={toggleAdvancedFilter}
                  >
                    Advanced Filter
                  </span>
                </div>
              </Col>
            </Row>
          )}
          {showAdvancedFilter && (
            <Row>
              <Col xl="12" className="{flex-end}">
                <AdvancedFilter clearSearch={clearSearch} search={search} />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <QuedTableContainer
                    columns={columns}
                    data={stops}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={50}
                    className="custom-header-css"
                    useSticky
                    loading={isLoading}
                    refreshTable={refreshTable}
                    renderRowSubComponent={renderRowSubComponent}
                    getTrProps={expandRow}
                    searchToday={searchToday}
                    searchMonth={searchMonth}
                    searchCustom={searchCustom}
                    searchWeek={searchWeek}
                    queryParam={queryParam}
                    totalRows={stops.length}
                    setActiveFilter={setActiveFilter}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OrderDashboard3
