import React from 'react'

const Confirmation = (props) => {

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Confirmation</h5>
                <button
                    type="button"
                    onClick={() => {
                        props.cancel()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true" onClick={() => props.cancel()}>
                        &times;
                    </span>
                </button>
            </div>

            <div className="modal-body">
                {props.message} 
            </div>

            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => props.confirm()}
                >
                    Confirm
                </button>
                <button
                    type="button"
                    className="btn btn-secodnary"
                    onClick={() => props.cancel()}
                >
                    Cancel
                </button>
            </div>
        </>
    )
}

export default Confirmation