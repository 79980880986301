import React, { useState } from "react"
import LoadingOverlay from "react-loading-overlay"
import {
  Button,
  Col,
  Input,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
  UncontrolledTooltip,
} from "reactstrap"

import validator from "validator"

const CellEditor = props => {
  const [newValue, setNewValue] = useState(props.value)
  const [oldValue, setOldOvalue] = useState(props.value)
  const [errorMessage, setErrorMessage] = useState(null)
  const [toast, setToast] = useState(false)
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  const changeMe = value => {
    setNewValue(value)
  }

  const toggleToast = () => {
    setToast(!toast)
  }

  const update = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      var url = api_url

      url = url + "locations/" + oldValue

      console.log(props.row)

      const config = {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newtmsLocationId: newValue.trim(),
          qdLocationId: props.row.original.quedLocationId,
        }),
      }

      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      setErrorMessage(null)
      console.log("hello success" + res.message)
      props.editRow(null)
      toggleToast()
      setLoading(false)
      props.refreshTable()
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
    }
  }

  const create = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      var url = api_url

      url = url + "locations"

      console.log(props.row)

      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tmsLocationId: newValue.trim(),
          qdLocationId: props.row.original.quedLocationId,
          locationName: props.row.original.tLocationName,
        }),
      }

      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      setErrorMessage(null)
      console.log("hello success" + res.message)
      props.editRow(null)
      toggleToast()
      setLoading(false)
      props.refreshTable()
    } catch (error) {
      console.error("Error:" + error.message)
      setLoading(false)
      console.error(error.message)
    }
  }

  const validate = value => {
    setNewValue(value)
  }

  const save = () => {
    // if (newValue.indexOf(" ") > 0) {
    //   setErrorMessage("White space is not allowed")
    //   return
    // }
    if (oldValue) {
      update()
    } else {
      create()
    }
  }

  return (
    <React.Fragment>
      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>Reactstrap</ToastHeader>
          <ToastBody>Hello, world! This is a toast message.</ToastBody>
        </Toast>
      </div>
      <LoadingOverlay
          active={loading}
          spinner
          
          
        >
      <Row>
        
          <Col md={8}>
            <Input
              type="text"
              value={newValue}
              onChange={e => validate(e.target.value)}
            />
          </Col>
          <Col className="d-flex justify-content-end" md={4}>
            <Button
              color="success"
              className="btn btn-sm"
              disabled={newValue?.length === 0 || newValue === oldValue}
              onClick={() => save()}
            >
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              color="secondary"
              className="btn btn-sm"
              onClick={() => props.editRow(null)}
            >
              Cancel
            </Button>
          </Col>
        
      </Row>
      </LoadingOverlay>
      {errorMessage && (
        <Row>
          <span className="text-sm text-danger">{errorMessage}</span>
        </Row>
      )}
    </React.Fragment>
  )
}

export default CellEditor
