import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardSubtitle, Col, Popover, PopoverBody, PopoverHeader, Row, Table } from 'reactstrap'
import Preview from './Preview'
import { useNavigate } from 'react-router-dom'
import VarData from './VarData'
import ValidationData from './ValidationData'
import MappingEditForm from './MappingEditForm'
import ConfigEditForm from './ConfigEditForm'

const PublishedData = (props) => {

    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [data, setData] = useState()
    const [hasStagedData, setHasStagedData] = useState(false)
    const [mLocId, setMLocId] = useState(props.m_loc_id)

    const api_url = process.env.REACT_APP_API_URL
    const jsonData = "{abc:def}"

    const navigate = useNavigate()

    useEffect(() => {
        getEmailTemplate()
    }, [])

    const edit = () => {
        setIsEditing(true)
    }

    const cancelEdit = () => {
        setIsEditing(false)
        props.getEmailTemplate()
    }

    const getEmailTemplate = async () => {
        try {
            setLoading(true)
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }
            const response = await fetch(api_url + "opendock/configmapping/" + props.m_loc_id, { headers })
            if (!response.ok) {
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            if (jsonData) {
                if (jsonData.published) {
                    setData(jsonData.published)
                }

                if (jsonData.staged) {
                    setHasStagedData(true)
                }
            }
            setLoading(false)
        } catch (error) {
            console.error("Error:", error)
            setLoading(false)
        }
    }

    const goToLocationManagement = () => {
        navigate("/location-management")
    }

    return (
        <Card>
            <CardBody>
                {loading && <div>Loading...</div>}
                {isEditing && <ConfigEditForm data={data} cancel={cancelEdit} qdLocationId={props.locationId} isPublised={true} m_loc_id={mLocId} />}
                {!loading && data && !isEditing &&
                    <Card className='p-3'>
                        <Row>
                            <Col>
                                <span className='text-primary text-size-16 m-2'>OpenDock Template Configuration</span>
                                <Badge color="success" className="me-1">Active</Badge>


                            </Col>
                            <Col className='d-flex justify-content-end gap-2'>
                                {hasStagedData ? <Button type="button" className="btn btn-soft-primary btn-sm waves-effect waves-light" onClick={props.showStagged}>Show Draft</Button> :
                                    <Button type="button" className="btn btn-soft-primary btn-sm waves-effect waves-light" onClick={edit}>Edit</Button>}
                            </Col>
                        </Row>
                        <CardSubtitle><span className='m-2 text-muted'>{props.locationName}</span></CardSubtitle>

                        <CardBody>
                            <Row>
                                <Card outline color="primary" className="border mt-3 mb-3">
                                    <CardBody className="p-2 cardBody">
                                      
                                        <Row>
                                            <VarData vars={data.vars} />
                                        </Row>
                                        <div className="border-bottom mb-2"></div>
                                        {/* <Row>
                                            <Col md="2" className={"text-muted"}>
                                                <span>Confirmation Message:</span>
                                            </Col>
                                            <Col md="10">
                                                {data.confirmation_msgs?.map((item, index) => (
                                                    <Row key={index}>
                                                        <p>{item}</p>
                                                    </Row>

                                                ))}

                                            </Col>
                                        </Row>
                                        <div className="border-bottom mb-2"></div>
                                        <Row>
                                            <Col md="2" className={"text-muted"}>
                                                <span>Signature:</span>
                                            </Col>
                                            <Col md="10">
                                                {data.sig_identifier_regex?.map((item, index) => (
                                                    <Row key={index}>
                                                        <p>{item}</p>
                                                    </Row>

                                                ))}

                                            </Col>
                                        </Row> */}
                                        {/* <div className="border-bottom mb-2"></div> */}
                                        <Row>
                                            <ValidationData validations={data.validations} isEdit={false} />
                                        </Row>
                                        {/* <div className="border-bottom mb-2"></div>
                                        <Row>
                                            <Col md="2" className={"text-muted"}>
                                                <span>To:</span>
                                            </Col>
                                            <Col md="10">
                                                <span className='text-primary'>{data.to}</span>
                                            </Col>
                                        </Row>
                                        <div className="border-bottom mb-2"></div>
                                        <Row>
                                            <Col md="2" className={"text-muted"}>
                                                <span>Cc:</span>
                                            </Col>
                                            <Col md="10">
                                                <span className='text-primary'>{data.cc}</span>
                                            </Col>
                                        </Row>
                                        <div className="border-bottom mb-2"></div>
                                        <Row>
                                            <Col md="2" className={"text-muted"}>
                                                <span>From:</span>
                                            </Col>
                                            <Col md="10">
                                                <span className='text-primary'>{data.from}</span>
                                            </Col>
                                        </Row> */}
                                    </CardBody>
                                </Card>
                                <Preview locationId={props.locationId} cfgType="published" m_loc_id={mLocId} />
                            </Row>
                            <div className="modal-footer">
                                <Button type="secondary" className="btn btn-secondary" onClick={goToLocationManagement}>Cancel</Button>
                            </div>
                        </CardBody>

                    </Card>}
            </CardBody>
        </Card>
    )
}

export default PublishedData