import QdContainer from 'components/Common/QdContainer'
import React from 'react'
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap'

const ClientCredGrantDetails = (props) => {

    const goBack = () => {
        props.goBack()
    }

    return (
        <Card className='p-3'>
            <h6 className='text-primary'>Auth Configuration - Client Credential Flow</h6>
            <CardBody>
                <Card outline color="primary" className="border mt-3 mb-3">
                    <CardBody className="p-2 cardBody">
                        <Row className="mb-2 font-weight-100">
                            <Col md="4" className={"text-muted"}>
                                <span>App Client Name:</span>
                            </Col>
                            <Col md="8">
                                <span>{props.data.app_client_name}</span>
                            </Col>
                        </Row>
                        <Row className="mb-2 font-weight-100">
                            <Col md="4" className={"text-muted"}>
                                <span>Provider Name:</span>
                            </Col>
                            <Col md="8">
                                <span>{props.data.provider_name}</span>
                            </Col>
                        </Row>
                        <Row className="mb-2 font-weight-100">
                            <Col md="4" className={"text-muted"}>
                                <span>App Client Id:</span>
                            </Col>
                            <Col md="8">
                                <span>{props.data.app_client_id}</span>
                            </Col>
                        </Row>
                        <Row className="mb-2 font-weight-100">
                            <Col md="4" className={"text-muted"}>
                                <span>Allowed Oauth Scopes</span>
                            </Col>
                            <Col md="8">
                                <span>{props.data.allowed_oauth_scopes.map((data, index) => (
                                    <Badge pill className="badge-soft-primary me-1" key={index} >
                                        {data}
                                    </Badge>
                                ))}</span>
                            </Col>
                        </Row>
                        <Row className="mb-2 font-weight-100">
                            <Col md="4" className={"text-muted"}>
                                <span>Access Token URL</span>
                            </Col>
                            <Col md="8">
                                <span>{props.data.access_token_url}</span>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <div className='modal-footer'>
                    <Button color='secondary' onClick={goBack}>Cancel</Button>
                </div>
            </CardBody>
        </Card>

    )
}

export default ClientCredGrantDetails