import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';

import jwt_decode from "jwt-decode";


const AuthCode = () => {


  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();

  const user_pool_domain_url = process.env.REACT_APP_USER_POOL_DOMAIN_URL
  const qued_portal_url = process.env.REACT_APP_QUED_PORTAL_URL
  const api_url = process.env.REACT_APP_API_URL


  const generateCodeVerifier = async () => {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode.apply(null, array))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashedVerifier = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode.apply(null, new Uint8Array(hashedVerifier)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }



  useEffect(() => {
    load_all_data()
    
    navigate("/dashboard")
  }, [])


  const load_all_data = async() => {
    var code = searchParams.get("code")
    var client = searchParams.get("client")
    console.log(code)
    console.log(user_pool_domain_url)

    await getAccessToken(code, client)
    await get_auth_config()
  }

  const get_auth_config = async() => {
    debugger
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "idp/code-grant", { headers })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      if (jsonData) {
        if (jsonData.logout_url){
          localStorage.setItem('logoutUrl', jsonData.logout_url)
        }
      } 
    } catch (error) {
      console.log(error)
      console.error("error occured")
    }
  }



  const getAccessToken = async (code, client) => {
    debugger
    const verifier = await generateCodeVerifier()
    const challenge = await generateCodeChallenge(verifier)
    var url = user_pool_domain_url + "oauth2/token?grant_type=authorization_code&client_id=" + client + "&redirect_uri="+qued_portal_url+"auth?client=" + client + "&code_challenge=" + challenge + "&code=" + code;

    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },

    }
    const response = await fetch(url, config)

    if (response.ok) {
      const res = await response.json()
      console.log(res);
      var decodedToken = jwt_decode(res.id_token, { complete: true });
      console.log(decodedToken)
      localStorage.setItem("idToken", res.id_token);
      localStorage.setItem("email", decodedToken.email)
      localStorage.setItem("user", decodedToken.given_name)
      localStorage.setItem("tenantId", decodedToken['custom:tenant'])
      
    } else {
      console.log(response)
    }
  }

  return (
    <div>Loading....</div>
  )
}

export default AuthCode