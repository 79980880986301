import React from "react"
import LoadingOverlay from "react-loading-overlay"

const EnableUserBox = props => {
  return (
    <LoadingOverlay active={props.loading} spinner>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmation</h5>
        <button
          type="button"
          onClick={() => props.setShowEnableModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={() => props.setShowEnableModal(false)}>
            &times;
          </span>
        </button>
      </div>

      <div className="modal-body">
        Are you sure you want to <span className="text-success">enable</span> the user{" "}
        <span className="text-primary">
          {props.user.attributes.given_name} {props.user.attributes.family_name}
        </span>{" "}
        ?
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.enable()}
        >
          Enable
        </button>
        <button
          type="button"
          className="btn btn-secodnary"
          onClick={() => props.setShowEnableModal(false)}
        >
          Cancel
        </button>
      </div>
    </LoadingOverlay>
  )
}

export default EnableUserBox
