import React, { useEffect, useState } from 'react'
import ViewTenantConfig from './ViewTenantConfig'


const OpenDockTenantConfig = (props) => {

    const [baseConfig, setBaseConfig] = useState(null)
    const [loading, setLoading] = useState(false)
    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
        getBaseConfig()
    }, [])

    const getBaseConfig = async() => {
        try {
            setLoading(true)
            const token = localStorage.getItem("idToken")
            
      
            const config = {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
      
            const response = await fetch(api_url + "locations/config/tenant/"+props.locationId, config)
      
            if (!response.ok) {
              throw new Error("Request failed")
            }
      
            const jsonData = await response.json()
            if (jsonData) {
                setBaseConfig(jsonData?.loc_config)
            }
            setLoading(false)
          } catch (error) {
            console.error("Error:", error)
            setLoading(false)
          }
    }

    return (
       <>
       <ViewTenantConfig data={baseConfig} locationId={props.locationId} refresh={getBaseConfig}></ViewTenantConfig>
       
       </>
    )
}

export default OpenDockTenantConfig