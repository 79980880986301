import React, { useState, useContext } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { AccountContext } from "pages/Auth/Account"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"
import Pool from "UserPool"
import jwt_decode from "jwt-decode";

// Redux
import { Link } from "react-router-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  UncontrolledAlert,
  ToastHeader,
  ToastBody,
  Toast,
} from "reactstrap"

// Formik validation

import LoginForm from "./LoginForm"
import ResetPasswordForm from "./ResetPasswordForm"
import { useEffect } from "react"

const Login3 = () => {
  const [show, setShow] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [resetpasswordSuccess, setResetPasswordSuccess] = useState(false)
  const [state, setState] = useState({
    isFirstLogin: false,
    user: null,
    userAttr: null,
  })

  const [toast, setToast] = useState(false)

  const toggleToast = () => {
    setToast(!toast)
  }

  useEffect(() => {
    if (localStorage.getItem("email") && isTokenValid()) {
     
      navigate("/dashboard")
      
    }
  }, [])

  const googlelogin = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=30618936715-lmu308r4spnasto11fv60sndk8plgs8q.apps.googleusercontent.com&redirect_uri=https://feddemohd.auth.us-east-1.amazoncognito.com/oauth2/idpresponse&scope=email%20profile&access_type=offline`;
    window.location.href = googleAuthUrl;
  }

  const isTokenValid = () => {
    let token = localStorage.getItem("idToken")
    if (token) {
      var decodedToken=jwt_decode(token, {complete: true});
      console.log("decoded token = " + decodedToken)
      var dateNow = new Date();
  
      console.log(decodedToken.exp * 1000 + "   " + dateNow.getTime())
      
      if(decodedToken.exp * 1000 < dateNow.getTime())
        return false

      return true;
    }
    return false
  }

  const navigate = useNavigate()

  //meta title
  document.title = "Login"

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username,
        Pool,
      })

      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      })

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: data => {
          console.log("from login " + data)
          resolve(data)
        },
        onFailure: err => {
          console.error("On Failure", err)
          reject(err)
        },

        newPasswordRequired: userAttr => {
          setState({
            isFirstLogin: true,
            user: cognitoUser,
            userAttr: userAttr,
          })
        },
      })
    })
  }

  const login = values => {
    authenticate(values.username.toLowerCase(), values.password)
      .then(data => {
        console.log(data.idToken.payload)
        console.log(data.idToken.payload['email'])
        localStorage.setItem("email", data.idToken.payload['email'])
        localStorage.setItem("user", data.idToken.payload.given_name)
        localStorage.setItem("idToken", data.idToken.jwtToken)
        localStorage.setItem("profilePic", data.idToken.payload.picture)
        localStorage.setItem('role', data.idToken.payload['custom:role'])
        localStorage.setItem('tenantId', data.idToken.payload['custom:tenant'])
        console.log("Role : " + data.idToken.payload['custom:role'])
        setHasError(false)
        var redirectUrl = localStorage.getItem("redirectUrl")
        console.log("redirect url " + redirectUrl)
        if (redirectUrl) {
          localStorage.removeItem("redirectUrl")
          navigate(redirectUrl)
        } else {
          navigate("/dashboard")
        }
       
      })
      .catch(error => {
        console.error("HD : " + error)
        setHasError(true)
        setErrorMessage(error.message)
      })
  }

  const changePassword = values => {
    const cognitoUser = state.user
    const userAttr = state.userAttr
    cognitoUser.completeNewPasswordChallenge(
      values.newPassword,
      {},
      {
        onSuccess: result => {
          toggleToast()
          setState({ isFirstLogin: false })
        },
        onFailure: err => {
          setHasError(true)
          setErrorMessage(err.message)
        },
      }
    )
  }

  // Form validation

  return (
    <div>
      {state.isFirstLogin ? (
        <ResetPasswordForm
          changePassword={changePassword}
          errorMessage={errorMessage}
          hasError={hasError}
        />
      ) : (
        <LoginForm
          login={login}
          errorMessage={errorMessage}
          hasError={hasError}
          googlelogin = {googlelogin}
        />
      )}

      <div
        className="position-fixed top-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>
            <img alt="" className="me-2 text-success" height="18" />
            Success
          </ToastHeader>
          <ToastBody className="text-success ">
            Password reset successfully, Please login with new password
          </ToastBody>
        </Toast>
      </div>
    </div>
  )
}

export default Login3
