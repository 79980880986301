import React, { useState } from "react"
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import CellEditor from "./CellEditor"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"

const LocationIdCell = props => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  const navigate = useNavigate()

  const save = newValue => {
    setLoading(true)
  }

  function tog_center(status) {
    setShowModal(!showModal)
  }

  const refreshTable = () => {
    props.refreshTable()
  }

  const gotoEmailTemplate = (appt_sys_ref) => {
    debugger
    var qdLocationId = props.row.original.quedLocationId
    var locationName = props.row.original.tLocationName
    if (appt_sys_ref == 'EMAIL') {
      navigate('/email-template', {state: {m_loc_id: props.row.original.m_loc_id, locationId: qdLocationId, locName:locationName}})
    } 

    // if (appt_sys_ref == 'OPENDOCK') {
    //   navigate('/opendock-template', {state: {m_loc_id: props.row.original.m_loc_id, locationId: qdLocationId, locName:locationName}})
    // }
  }

  const gotoEmailAddressConfig = () => {
    var qdLocationId = props.row.original.quedLocationId
    var locationName = props.row.original.tLocationName
    navigate('/email-address-config/'+props.row.original.m_loc_id+'/'+locationName)
  }

  const gotoAddNewLocation = (appt_sys_ref) => {
    var qdLocationId = props.row.original.quedLocationId
    var locationName = props.row.original.tLocationName
    if (appt_sys_ref == 'EMAIL') {
      navigate('/new-location-email', {state: {m_loc_id: props.row.original.m_loc_id, locationId: qdLocationId, locName: locationName}})
    } 

    // if (appt_sys_ref == 'OPENDOCK') {
    //   navigate('/opendock-loc/', {state: {m_loc_id: props.row.original.m_loc_id, locationId: qdLocationId, locName: locationName}})
    // }
    
  }

  const remove = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      var url = api_url

      if (props.row.original.locationId?.length > 0) {
        url = url + "locations/" + props.value
      } else {
        url = url + "email/templatemapping/" + props.row.original.m_loc_id 
      }

      
      console.log(props.row)

      const config = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
          
        },
        body: JSON.stringify({
          qdLocationId: props.row.original.quedLocationId,
        }),
        
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setLoading(false)
        throw new Error(res.message)
      }
      const res = await response.json()
      console.log("hello success" + res.message)
      props.editRow(null)
      setShowModal(false)
      setLoading(false)
      props.refreshTable()
    } catch (error) {
      console.error("Error:" + error)
      console.error(error.message)
    }
  }

  const showConfirm = () => {
    setShowModal(!showModal)
  }

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="md"
      >
        <LoadingOverlay active={loading} spinner>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowModal(false)}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body">
            Are you sure you want to delete the location id{" "}
            <span className="text-primary">{props.value}</span> ?
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => remove()}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secodnary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </LoadingOverlay>
      </Modal>
      {loading && (
        <div
          className="d-flex justify-content-center"
          style={{ height: "100%", backgroundColor: "#EEE" }}
        >
          <Spinner
            className="ms-2"
            color="primary"
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              zIndex: "100",
            }}
          />
        </div>
      )}
      {props.editingRow != props.row.id && (
        <Row>
          <Col md={8}>
            {props.value} &nbsp; {props.row.original.has_stage  && <Badge color="secondary" className="me-3">Draft</Badge>}
          </Col>
          <Col md={4} className="d-flex justify-content-end">
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="a"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {(props.value && props.value?.length > 0) && <DropdownItem
                  href="#"
                  onClick={() => props.editRow(props.row)}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  Edit
                
                </DropdownItem>}
                {!props.value &&  !props.row.original.stage_loc_id && (props.row.original.appt_sys_ref == 'EMAIL') && localStorage.getItem("role") == "admin" &&<DropdownItem
                // {!props.value &&  !props.row.original.stage_loc_id && (props.row.original.appt_sys_ref == 'EMAIL' || props.row.original.appt_sys_ref == 'OPENDOCK') && localStorage.getItem("role") == "admin" &&<DropdownItem
                  href="#"
                  onClick={() => gotoAddNewLocation(props.row.original.appt_sys_ref)}
                >
                  <i className="mdi mdi-plus font-size-16 text-primary me-1" />{" "}
                  Add
                </DropdownItem>}
                {!props.value &&   (props.row.original.appt_sys_ref !== 'EMAIL' ) &&<DropdownItem
                  href="#"
                  onClick={() => props.editRow(props.row)}
                >
                {/* {!props.value &&   (props.row.original.appt_sys_ref !== 'EMAIL' &&  props.row.original.appt_sys_ref !== 'OPENDOCK') &&<DropdownItem
                  href="#"
                  onClick={() => props.editRow(props.row)}
                > */}
                  <i className="mdi mdi-plus font-size-16 text-primary me-1" />{" "}
                  Add
                </DropdownItem>}

                {(props.value  || props.row.original.stage_loc_id)  && (

                    <DropdownItem
                    href="#"
                    onClick={() => showConfirm()}
                    >
                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                    Delete
                    </DropdownItem>

                
                )}
                 {(props.row.original.appt_sys_ref == 'EMAIL' ) && (props.value || props.row.original.stage_loc_id) && localStorage.getItem("role") == "admin" && <DropdownItem
              //  {(props.row.original.appt_sys_ref == 'EMAIL' || props.row.original.appt_sys_ref == 'OPENDOCK') && (props.value || props.row.original.stage_loc_id) && localStorage.getItem("role") == "admin" && <DropdownItem
                  href="#"
                  onClick={() => gotoEmailTemplate(props.row.original.appt_sys_ref)}
                >
                  <i className="mdi mdi-card-bulleted-settings-outline text-success me-1" />{" "}
                  Template Configuration
                </DropdownItem>}
                {props.row.original.appt_sys_ref == 'EMAIL'  &&  (props.value || props.row.original.stage_loc_id)  && <DropdownItem
                  href="#"
                  onClick={gotoEmailAddressConfig}
                >
                  <i className="mdi mdi-email-edit-outline text-primary me-1" />{" "}
                  Email Address Config
                </DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
            &nbsp;&nbsp;&nbsp;&nbsp;

            
            {/* {!props.value && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>} */}
</Col>
        </Row>
      )}
      {props.editingRow == props.row.id && (
        <CellEditor
          value={props.value}
          editRow={props.editRow}
          onSave={save}
          row={props.row}
          refreshTable={refreshTable}
        />
      )}
    </div>
  )
}

export default LocationIdCell
