import QdContainer from 'components/Common/QdContainer'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import SectionDetails from './SectionDetails'
import FormCard from './FormCard'
import ListCard from './ListCard'

const SystemConfiguration = () => {

    const [sysConfig, setSysConfig] = useState()
    const [loading, setLoading] = useState(false)
    const [cardData, setCardData] = useState(null)
    const [showCardData, setShowCardData] = useState(false)
    const [selectedSection, setSelectedSection] = useState()


    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true)
        fetch('/systemConfigurations.json').then(response => {
            if (response.ok) {
                return response.text()
            }
        }).then(text => setSysConfig(JSON.parse(text)))
            .catch(error => console.log(error))

        setLoading(false)

    }

    const resetCard = () => {
        setSelectedSection()
        setShowCardData(false)
        setCardData(null)
    }

    const setCard = (card, sectionName) => {
        setSelectedSection(sectionName)
        setShowCardData(true)
        setCardData(card)
    }

   

    return (
        <QdContainer>
            <Card>
                <CardBody>
                    <Col>
                        <div className="float-start">
                            <h5 className="text-primary">System Configuration</h5>
                        </div>
                    </Col>
                    <Col>
                        <div className="float-end">
                            <div className="d-flex gap-2">
                                <div className="text-sm">
                                    <span
                                        onClick={() => {
                                            setShowCardData(false)
                                            setCardData(null)
                                            loadData()
                                        }}
                                        className="pointer text-primary"
                                    >
                                        <i className="fas fa-sync-alt" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </CardBody>
            </Card>

            {!loading  && !showCardData && sysConfig && sysConfig.section?.map((item, index) => (
                <SectionDetails data={item} key={index} setCard={setCard}/>
            ))}

            {loading && <div>Loading...</div>}

            {showCardData && cardData && cardData.form_type === 'form' && <FormCard card={cardData} reset={resetCard} resetCard={resetCard} section={selectedSection} cancel={resetCard}/>}
            {showCardData && cardData && cardData.form_type === 'list' && <ListCard card={cardData} reset={resetCard} resetCard={resetCard} section={selectedSection} cancel={resetCard}/>}

        </QdContainer>
    )
}

export default SystemConfiguration