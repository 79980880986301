import React from "react"

const CreateNew = (props) => {
  return (
    <div className="p-5">
      <div className="mb-2 d-flex justify-content-center">
        No notification found, create new notification
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button className="btn btn-primary" onClick={() => {props.setNew()}}>Create</button>
      </div>
    </div>
  )
}

export default CreateNew
