import Error from "pages/NotificationModal/Error"
import Success from "pages/NotificationModal/Success"
import React, { useState } from "react"
import LoadingOverlay from "react-loading-overlay"

const CancelConfirm = (props) => {
  const [loading, setLoading] = useState(false)
  const [isCancelling, setIsCancelling] = useState(true)
  const [success, setSuccess] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  const closeModal = () => {
    props.closeModal()
  }

  const cancelAppt = async() => {
    debugger;
    try {
        setLoading(true)
        const token = localStorage.getItem("idToken")
        const config = {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                stopIdList: props.data.stopIdList,
                apptSysType: props.data.apptSysType
            }),
        }
        const response = await fetch(api_url + "appointment/req-scheduler", config)
  
        if (!response.ok) {
          throw new Error("Request failed")
        }
  
        const jsonData = await response.json()
        console.log(jsonData)
        //setData(jsonData)
        setLoading(false)
        setIsCancelling(false)
        setSuccess(true)
      } catch (error) {
        console.error("Error:", error)
        setLoading(false)
        setSuccess(false)
      }

  }

  return (
    <>
    {isCancelling && <LoadingOverlay active={loading} spinner>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmation</h5>
        <button
          type="button"
          onClick={() => props.closeModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span
            aria-hidden="true"
            onClick={() => props.closeModal()}
          >
            &times;
          </span>
        </button>
      </div>

      <div className="modal-body">
        Are you sure you want to <span className="text-red">cancel</span>{" "}
        the appointment request for stop ids <span className="text-primary">
        {props.data?.stopIdList}</span> and location id {" "}
        <span className="text-primary">
          {props.data?.reqEvent.tmsStopLocId} 
        </span>{" "}
        ?
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={cancelAppt}
        >
          Yes
        </button>
        <button
          type="button"
          className="btn btn-secodnary"
          onClick={() => props.closeModal()}
        >
          Cancel
        </button>
      </div>
    </LoadingOverlay>}
    {!isCancelling && success && <Success message="Appointment cancelled successfulluy" close={closeModal}/>}
    {!isCancelling && !success && <Error message="Failed to cancel the appointment" />}
    </>
  )
}

export default CancelConfirm
