import React, {useState, forwardRef, useImperativeHandle} from "react"
import { Card, CardBody, Col } from "reactstrap"
import classes from "./OrderWidget.scss"
import { Radio } from "@mui/material"
import {publish} from '../../common/events';

// eslint-disable-next-line react/display-name
const OrderWidget = forwardRef((props, ref) => {
  const reports = props.counters

  const[active, setActive] = useState('')

  const filterRequired = () => {
    publish('hideExpandedRow');
    if (active === 'new-request') {
      setActive('')
      props.filterDefault();
    } else {
      setActive('new-request')
      props.filterRequired();
    }
   
  }

  const filterAll = () => {
    publish('hideExpandedRow');
    if (active === 'all') {
      setActive('')
      props.filterAll();
    } else {
      setActive('all');
      props.filterAll()
    }
  }

  const filterSubmitted = () => {
    publish('hideExpandedRow');
    if (active === 'submitted') {
      setActive('');
      props.filterDefault();
    } else {
      setActive('submitted')
      props.filterSubmitted();
    }
    
  }

   useImperativeHandle(ref, () => ({
    resetActive: resetActive
  }));

  const resetActive = () =>{
    setActive('')
  }

  const filterException = () => {
    publish('hideExpandedRow');
    if (active === 'exception') {
      setActive('');
      props.filterDefault();
    } else {
      setActive('exception')
      props.filterException();
    }
  }

  const filterConfirmed = () => {
    publish('hideExpandedRow');
    if (active === 'confirmed') {
      setActive('')
      props.filterDefault();
    } else {
      setActive('confirmed')
      props.filterConfirmed();
    }
    
  }

  const filterAwaitingApprovals = () => {
    publish('hideExpandedRow');
    if (active === 'awaiting-approvals') {
      setActive('')
      props.filterDefault();
    } else {
      setActive('awaiting-approvals')
      props.filterAwaitingApprovals();
    }
  }

  return (
    <React.Fragment>
      <Col lg="2" >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer " + (active === "all" ? "active-all": "") } id="newRequestCard" onClick={filterAll}>
        <CardBody className="pt-1 pb-1">
            <div className="d-flex align-items-center mb-3 text-primary">
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle bg-primary font-size-16"
                  }
                >
                  <i className="bx bx-copy-alt" />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-primary"}>{"All"}</h5>
             
            </div>
            <div className={"mt-4 d-flex justify-content-center text-primary pointer"}>
              <h4 className={"text-primary"}>{reports.ALL}</h4>
              <div className="d-flex">
                <span
                  className={
                    "badge badge-soft-" + reports.color + " font-size-14"
                  }
                ></span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="2" >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer "+ (active === "awaiting-approvals" ? "active-pending-approvals": "") } onClick={filterAwaitingApprovals}>
          <CardBody className="pt-1 pb-1">
            <div className={"d-flex align-items-center mb-3 text-white " }>
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle  bg-warning font-size-16"
                  }
                >
                  <i className="bx bx-calendar-check " />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-warning"}>{"Pending Requests"}</h5>
            </div>
            <div className={"mt-4 d-flex justify-content-center text-white"}>
              <h4 className={"text-warning"}>{reports.PENDING_APPROVAL}</h4>
              {/* <div className="d-flex">
                <span
                  className={
                    "badge badge-soft-" +
                    "bg-success" +
                    " font-size-14"
                  }
                ></span>{" "}
              </div> */}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="2" >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer "+ (active === "new-request" ? "active-new-request": "") } onClick={filterRequired}>
          <CardBody className="pt-1 pb-1">
            <div className={"d-flex align-items-center mb-3 text-white " }>
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle bg-yellow font-size-16"
                  }
                >
                  <i className="bx bx-calendar-check " />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-yellow"}>{"New Requests"}</h5>
            </div>
            <div className={"mt-4 d-flex justify-content-center text-white"}>
              <h4 className={"text-yellow"}>{reports.REQUIRED}</h4>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="2" >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer " + (active === "submitted" ? "active-submitted": "")} onClick={filterSubmitted}>
        <CardBody className="pt-1 pb-1">
            <div className="d-flex align-items-center mb-3 text-white">
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle  bg-secondary-orange font-size-16"
                  }
                >
                  <i className="bx bxs-hourglass-top" />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-secondary-orange"}>{"Submitted"}</h5>
            </div>
            <div className={"mt-4 d-flex justify-content-center text-secondary-orange"}>
              <h4 className={"text-secondary-orange"}>{reports.REQUESTED}</h4>
              <div className="d-flex">
                <span
                  className={
                    "badge badge-soft-" +
                    "bg-secondary-orange" +
                    " font-size-14"
                  }
                ></span>{" "}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="2" >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer " + (active === "exception" ? "active-exception": "")} onClick={filterException}>
        <CardBody className="pt-1 pb-1">
            <div className="d-flex align-items-center mb-3 text-white">
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle  bg-danger font-size-16"
                  }
                >
                  <i className="bx bx-calendar-x" />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-danger"}>{"Exception"}</h5>
            </div>
            <div className={"mt-4 d-flex justify-content-center text-white"}>
              <h4 className={"text-danger"}>{reports.EXCEPTION}</h4>
              <div className="d-flex">
                <span
                  className={
                    "badge badge-soft-" +
                    "bg-danger" +
                    " font-size-14"
                  }
                ></span>{" "}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="2"  >
        <Card className={"bg-white glow-card pt-1 pb-1 pointer "+ (active === "confirmed" ? "activeConfirm": "") } onClick={filterConfirmed}>
          <CardBody className="pt-1 pb-1">
            <div className={"d-flex align-items-center mb-3 text-white " }>
              <div className="avatar-xs me-3">
                <span
                  className={
                    "avatar-title rounded-circle  bg-success font-size-16"
                  }
                >
                  <i className="bx bx-calendar-check " />
                </span>
              </div>
              <h5 className={"font-size-16 mb-0 text-success"}>{"Confirmed"}</h5>
              {/* {<input name="plan" className={"radio"} type="radio" onClick={filterConfirmed} id="confirmedRadio" />} */}
            </div>
            <div className={"mt-4 d-flex justify-content-center text-white"}>
              <h4 className={"text-success"}>{reports.CONFIRMED}</h4>
              <div className="d-flex">
                <span
                  className={
                    "badge badge-soft-" +
                    "bg-success" +
                    " font-size-14"
                  }
                ></span>{" "}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      
    </React.Fragment>
  )
});

export default OrderWidget
