import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import logo1 from "../../assets/images/Logo-final.png"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from "reactstrap"

const ResetPasswordForm = props => {
  const [show, setShow] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: values => {
      props.changePassword(values)
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <img src={logo1} height="34" />
                  </div>
                  <div
                    className="mb-3 text-primary justify-content-center"
                    style={{ display: "flex" }}
                  >
                    <h6>Reset your password</h6>
                  </div>

                  {props.hasError && (
                        <div className="mb-3">
                          <UncontrolledAlert color="danger" role="alert">
                            {props.errorMessage}
                          </UncontrolledAlert>
                        </div>
                      )}


                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="newPassword"
                            value={validation.values.newPassword || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter new password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.newPassword &&
                              validation.errors.newPassword
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.newPassword &&
                        validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Reset
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordForm
