import React, {useState} from "react"
import { Button, Modal } from "reactstrap"

const DetailReference = ({ fulltext }) => {
  const [modal_center, setmodal_center] = useState(false)

  const tog_center = status => {
    setmodal_center(!modal_center)
  }

  return (
    <div>
      <Button
        color="primary"
        className="btn-sm m-0 p-1"
        outline
        onClick={() => {
          tog_center(fulltext)
        }}
      >
        See more
      </Button>

      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">References</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={() => tog_center(false)}>
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          {
            <>
              {fulltext &&
                fulltext.map((i, index) => (
                  <p
                    key={index}
                    style={{
                      marginBottom: "0px!important",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    {i}
                  </p>
                ))}
            </>
          }
        </div>
      </Modal>
    </div>
  )
}

export default DetailReference;