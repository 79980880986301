import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

const SuccessPage = () => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col className="d-flex justify-content-center">
                  <i className="fas fa-check-double font-size-28 text-success"></i>{" "}
                </Col>
              </Row>
              <Row className="">
                <Col className="font-size-20 text-success d-flex justify-content-center">
                  Location mapping created successfully !!!
                </Col>
              </Row>
              <Row className="mt-10">
              <Col className=" d-flex justify-content-center">
              <Button
                  color="primary"
                  className="btn btn-primary"
                  onClick={navigate("/location-management")}
                >
                  {" "}
                  Go Back{" "}
                </Button>
                </Col>
                
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SuccessPage
