import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Form, FormFeedback, Input, Label, Modal, Row } from 'reactstrap'
import * as Yup from "yup"
import Success from 'pages/NotificationModal/Success'
import LoadingOverlay from 'react-loading-overlay'
import { useNavigate } from 'react-router-dom'

const IdpForm = () => {

    const [loading, setLoading] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [tenant, setTenant] = useState()

    const api_url = process.env.REACT_APP_API_URL

    const navigate = useNavigate()

    useEffect(() => {
        loadTenantData()

    }, [])

    const loadTenantData = async () => {
        try {
            setLoading(true)
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }

            var url = api_url

            url = url + "tenant"

            const response = await fetch(url, { headers })

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            setTenant(jsonData)
            setLoading(false)
        } catch (error) {
            console.error("Error:" + error)
            setLoading(false)
            console.error(error)
        }
    }




    const cancel = () => {
        navigate('/auth-config/code')
    }

    const save = async (values) => {

        setLoading(true)
        console.log(values)
        try {
            const token = localStorage.getItem("idToken")
            var url = api_url

            url = url + "idp/code-grant"
            var provider_name = tenant.shortname.replace(/_/g, '-') + '-' + values.provider_type
            var clientName = tenant.shortname.replace(/_/g, '-') + '-' +values.provider_type + '-client'

            const config = {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    provider_name: provider_name,
                    provider_type: values.provider_type,
                    oidc_issuer_url: values.oidc_issuer_url,
                    client_id: values.client_id,
                    client_secret: values.client_secret,
                    client_name: clientName,
                }),
            }
            const response = await fetch(url, config)

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                const res = await response.json()
                setError(res.error)
                setLoading(false)
                throw new Error(res.error)
            }
            debugger
            setLoading(false)
            //setTotasttype("Success")
            setShowSuccessModal(true)
        } catch (error) {
            console.error("Error:" + error.message)
            console.error(error.message)
            setLoading(false)
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            //provider_name: "",
            provider_type: "OIDC",
            oidc_issuer_url: "",
            client_id: "",
            client_secret: "",
            //logout_url: ""
            //client_name: ""

        },
        validationSchema: Yup.object({
            // provider_name: Yup.string().required("Please Enter the name of provider"),
            oidc_issuer_url: Yup.string().required("Please Enter Issuer URL"),
            client_id: Yup.string().required("Please enter client id"),
            client_secret: Yup.string().required("Please enter client secret"),
            //logout_url: Yup.string().required('Please enter logout url')
            // client_name: Yup.string().required("Please enter client name"),
        }),

        onSubmit: values => {
            save(values)
        },
    })

    const togSuccessModal = () => {
        setShowSuccessModal(!showSuccessModal)
        navigate('/auth-config/code')
    }


    return (
        <div>
            <Modal
                isOpen={showSuccessModal}
                toggle={() => {
                    togSuccessModal()
                }}
                centered
                size="sm"
            >
                <Success message='Identity provider created successfully' close={togSuccessModal} />
            </Modal>
            <Card>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <CardBody className='p-4'>
                        <LoadingOverlay active={loading} spinner>
                            <h5 className="mb-4 text-primary">Create New Identity Provider</h5>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Provider Type
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="provider_type"
                                        id="horizontal-firstname-Input"
                                        placeholder="Enter the name of provider"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.provider_type}
                                        disabled
                                        invalid={
                                            validation.touched.provider_type &&
                                                validation.errors.provider_type
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.provider_type &&
                                        validation.errors.provider_type ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.provider_type}
                                        </FormFeedback>
                                    ) : null}

                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Client Id
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="client_id"
                                        id="horizontal-firstname-Input"
                                        placeholder="Enter the client id"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.client_id}
                                        invalid={
                                            validation.touched.client_id &&
                                                validation.errors.client_id
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.client_id &&
                                        validation.errors.client_id ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.client_id}
                                        </FormFeedback>
                                    ) : null}

                                </Col>
                            </Row>



                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Client Secret
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="client_secret"
                                        id="horizontal-firstname-Input"
                                        placeholder="Enter the client secret"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.client_secret}
                                        invalid={
                                            validation.touched.client_secret &&
                                                validation.errors.client_secret
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.client_secret &&
                                        validation.errors.client_secret ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.client_secret}
                                        </FormFeedback>
                                    ) : null}

                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    OIDC Issuer URL
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="oidc_issuer_url"
                                        id="horizontal-firstname-Input"
                                        placeholder="Enter the Issuer URL eg https://okta.com"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.oidc_issuer_url}
                                        invalid={
                                            validation.touched.oidc_issuer_url &&
                                                validation.errors.oidc_issuer_url
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.oidc_issuer_url &&
                                        validation.errors.oidc_issuer_url ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.oidc_issuer_url}
                                        </FormFeedback>
                                    ) : null}

                                </Col>
                            </Row>

                            {/* <Row className="mb-4">
                                <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-3 col-form-label"
                                >
                                    Logout URL
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="logout_url"
                                        id="horizontal-firstname-Input"
                                        placeholder="Enter the Logout URL eg https://okta.com"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.logout_url}
                                        invalid={
                                            validation.touched.logout_url &&
                                                validation.errors.logout_url
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.logout_url &&
                                        validation.errors.logout_url ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.logout_url}
                                        </FormFeedback>
                                    ) : null}

                                </Col>
                            </Row> */}

                            <Row className="d-flex justify-content-end">
                                <Col sm={9}>
                                    <div>
                                        <Button type="submit" color="primary" className="w-md">
                                            Submit
                                        </Button>
                                        {"     "}
                                        <Button
                                            type="button"
                                            color="secondary"
                                            className="w-md"
                                            onClick={() => {
                                                cancel()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </LoadingOverlay>
                    </CardBody>

                </Form>

            </Card>
        </div>
    )
}

export default IdpForm