import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import UserCard from "./UserCard"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

const UserAdmin = () => {
  document.title = "User Management | Qued Portal"
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState(false)
  const api_url = process.env.REACT_APP_API_URL
  const toggleToast = () => {
    setToast(!toast)
  }


  const navigate = useNavigate()

  //v4azg6pf68.execute-api.us-east-1.amazonaws.com/prod/users/list

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    try {
      setLoading(true)
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(api_url + "users", { headers })

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      setUsers(jsonData)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const addUser = () => {
    navigate("/create-user")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Col>
                <div className="float-start">
                  <h5 className="text-primary">User Management</h5>
                </div>
              </Col>
              <Col>
                <div className="float-end">
                  <div className="d-flex gap-2">
                    <div className="text-sm">
                      <span style={{ marginRight: "20px" }}>
                        <Link to="/create-user" onClick={addUser}>
                          <i className="fas fa-user-plus" />
                        </Link>
                      </span>

                      <span
                        onClick={() => {
                          getUsers()
                        }}
                        className="pointer text-primary"
                      >
                        <i className="fas fa-sync-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </CardBody>
          </Card>

          <Row className="mt-1">
            {!loading &&
              users?.length > 0 &&
              users.map((user, index) => (
                <UserCard
                  key={index}
                  user={user}
                  refreshUsers={getUsers}
                  currentUserEmail={localStorage.getItem("email")}
                />
              ))}
          </Row>
          {loading && (
            <Row>
              <div>Loading..</div>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserAdmin
