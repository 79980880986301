import React, { useEffect, useState } from "react"
import { JsonEditor as Editor } from "jsoneditor-react"
import "jsoneditor-react/es/editor.min.css"

import classes from "./JsonEditor.scss"
import { Button, Input } from "reactstrap"

const JsonEditor = props => {
  const [sampleJsonEvent, setSampleJsonEvent] = useState(props.data)
  const [sourceJsonEvent, setSourceJsonEvent] = useState(
    JSON.stringify(props.data)
  )
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)


  useEffect(() => {
    setLoading(true)
    fetch("/sampleEvent.json")
      .then(response => {
        if (response.ok) {
          return response.text()
        }
      })
      .then(text => {
        setSourceJsonEvent(text)
        setSampleJsonEvent(JSON.parse(text))
        setLoading(false)
      })
      .catch(error => console.log(error))
  }, [])

  const handleEdit = updatedData => {
    setSampleJsonEvent(updatedData)
    props.setSampleJsonEvent(updatedData)
  }

  const handleInputChange = e => {
    var jsonData = e.target.value;
    setSourceJsonEvent(jsonData)
    //setSampleJsonEvent(JSON.parse(jsonData))
  }

  const handleOKClick = () => {
    let parsedJsonData = JSON.parse(sourceJsonEvent)
    setSampleJsonEvent(parsedJsonData)
    props.setSampleJsonEvent(parsedJsonData)
    setIsEdit(false)
    props.setIsEdit(false)
  }

  const handleEditSource = () => {
    setIsEdit(true)
    props.setIsEdit(true)
  }

  return (
    <>
      {!loading && sampleJsonEvent && !isEdit && (
        <>
          <Editor value={sampleJsonEvent} onChange={handleEdit} />
          <div className="float-end d-flex mt-2">
            <Button className="btn " color="link" onClick={handleEditSource}>
              Edit source json
            </Button>
          </div>
        </>
      )}
      {!loading && isEdit && (
        <>
          <textarea
            className="form-control"
            rows={10}
            cols={50}
            value={sourceJsonEvent}
            onChange={handleInputChange}
            placeholder="Enter JSON data here..."
          />
          <div className="float-end d-flex mt-2">
            <Button
              color="primary"
              className="btn btn-sm"
              onClick={handleOKClick}
              outline
            >
              OK
            </Button>
          </div>
        </>
      )}
      {loading && <span>loading...</span>}
    </>
  )
}

export default JsonEditor
