import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const SamlDetails = () => {
    const [loading, setLoading] = useState(false)
    const api_url = process.env.REACT_APP_API_URL
    const [data, setData] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            setLoading(true)
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }
            const response = await fetch(api_url + "idp/saml", { headers })

            if (!response.ok) {
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            if (jsonData) {
                setData(jsonData)
            }
           
            setLoading(false)
        } catch (error) {
            console.error("Error:", error)
            setLoading(false)
        }


        
    }

    const openSAMLEditForm = () => {
        navigate('/auth-config-saml/new')
    }

    return (
        <>
            {!loading && data && <div>
                {showProviderDetails(data)}

                {showClientDetails(data)}

            </div>}
            {loading && !data && <div>Loading..</div>}
            {!loading && !data &&
                <Card>
                    <CardBody className='p-3'>
                        <div className='text-center mt-3'>
                            <span className='mt-3'>
                                No configuration found
                            </span>
                            <div className='mt-4'>
                                <Button type="button" color="primary" className="w-md" onClick={openSAMLEditForm}>
                                    Create New
                                </Button>
                            </div>

                        </div>
                    </CardBody>

                </Card>
            }
        </>
    )

}

export default SamlDetails

function showClientDetails(data) {
    return <Card outline color="primary" className="border mt-3 mb-3">
        <CardBody className="p-2 cardBody">
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>App Client Name:</span>
                </Col>
                <Col md="8">
                    <span>{data?.client_response.app_client_name}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>App Client Id:</span>
                </Col>
                <Col md="8">
                    <span>{data?.client_response.app_client_id}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Callback Url</span>
                </Col>
                <Col md="8">
                    <span>{data.client_response.call_back_urls}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Sign-in Redirect Url</span>
                </Col>
                <Col md="8">
                    <a href={data?.sign_in_redirect_uri}>{data.sign_in_redirect_uri}</a>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Tenant Login URL</span>
                </Col>
                <Col md="8">
                    <a href={data?.tenant_login_url}>{data.tenant_login_url}</a>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Logout URL</span>
                </Col>
                <Col md="8">
                    <span>{data?.logout_url}</span>
                </Col>
            </Row>
        </CardBody>
    </Card>
}


function showProviderDetails(data) {

    return <Card outline color="primary" className="border mt-3 mb-3">
        <CardBody className="p-2 cardBody">
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Provider Name:</span>
                </Col>
                <Col md="8">
                    <span>{data.idp_response.provider_name}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Provider Type:</span>
                </Col>
                <Col md="8">
                    <span>{data.idp_response.provider_type}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>Meatadata URL:</span>
                </Col>
                <Col md="8">
                    <span>{data.idp_response.metadata_url}</span>
                </Col>
            </Row>
            <Row className="mb-2 font-weight-100">
                <Col md="4" className={"text-muted"}>
                    <span>SSO binding redirect url:</span>
                </Col>
                <Col md="8">
                    <span>{data.idp_response.sso_binding_redirect_url}</span>
                </Col>
            </Row>
            
        </CardBody>
    </Card>
}