import React, { useEffect, useState } from "react"
import { Tbody, Thead } from "react-super-responsive-table"
import { Button, Modal, Table } from "reactstrap"
import EmailGroupEditForm from "./EmailGroupEditForm"

const EmailGroupData = ({ groups, isEdit, updateEmailGroup }) => {
  const [editingGroup, setEditingGroup] = useState(null)
  const [editingGroupIndex, setEditingGroupIndex] = useState(null)
  const [showAddForm, setShowAddForm] = useState(false)
  const [existingGroups, setExistingGroups] = useState(groups)
  const [loading, setLoading] = useState(false)

  const togAddForm = () => {
    setShowAddForm(!showAddForm)
  }

  const editGroup = (val, index) => {
    setEditingGroup(val)
    setEditingGroupIndex(index)
    setShowAddForm(true)
  }

  const removeGroup = index => {
    let newGroups = groups?.filter((item, idx) => idx !== index)
    setExistingGroups(newGroups)
    updateEmailGroup(newGroups)
  }

  const addGroup = () => {
    setEditingGroup(null)
    setEditingGroupIndex(null)
    setShowAddForm(true)
  }

  useEffect(() => {
    setExistingGroups(groups)
  }, [])
  

  const saveGroup = val => {
    console.log("value from add new group - ", val)

    if (editingGroup) {
      var eGroups = [...groups]
      eGroups[editingGroupIndex] = val
      setExistingGroups(eGroups)
      updateEmailGroup(eGroups)
    } else {
      if (groups) {
        var eGroups = [...groups]
        eGroups.push(val)
        setExistingGroups(eGroups)
        updateEmailGroup(eGroups)
      } else {
        var existingGroups = []
        existingGroups.push(val)
        updateEmailGroup(existingGroups)
      }
    }

    setEditingGroup(null)
    setEditingGroupIndex(null)
    togAddForm()
  }

  return (
    <React.Fragment>
      <Modal isOpen={showAddForm} toggle={togAddForm}>
        <EmailGroupEditForm
          closeModal={togAddForm}
          onSave={saveGroup}
          groupData={editingGroup}
          groups={groups}
          isNew={editingGroup ? false : true}
          isEdit={isEdit}
          loading={loading}
        />
      </Modal>

      <Table>
        <Thead>
          <tr>
            <th style={{ border: "none" }}>Group Name</th>
            <th style={{ border: "none" }}>To List</th>
            <th style={{ border: "none" }}>CC List</th>
            {isEdit && <th style={{ border: "none" }}>Action</th>}
          </tr>
        </Thead>
        <Tbody>
          {existingGroups.map((group, index) => {
           return <tr key={index}>
              <td style={{ border: "none" }}>{group.groupName}</td>
              <td style={{ border: "none" }}>{group.to}</td>
              <td style={{ border: "none" }}>{group.cc}</td>
              {isEdit && (
                <td style={{ border: "none" }}>
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1 pointer"
                    onClick={() => editGroup(group, index)}
                  />{" "}
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    onClick={() => removeGroup(index)}
                  />
                </td>
              )}
            </tr>
          })}
        </Tbody>
      </Table>
      <Button className="btn btn-sm" color="primary"  onClick={addGroup}>
        Add
      </Button>
    </React.Fragment>
  )
}

export default EmailGroupData
