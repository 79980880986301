import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { Form, FormFeedback, Input, Label } from "reactstrap"
import * as Yup from "yup"

function GroupCredForm({ credData, onSave, isNew, closeModal }) {


  const [isDefault, setIsDefault] = useState(credData ? credData.group_name == 'default' ? true : false : true)

  

  const handleTypeChange = (event) => {
    event.preventDefault()
    setIsDefault(event.target.value === 'true'? true : false)
  }


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group_name: credData ? credData.group_name : isDefault ? "default" : "",
      userId: credData?.userId || "",
      password: credData?.password || "",
      retype: credData?.password || "",
      isDefault: credData?.group_name == 'default' || false
    },
    validationSchema: Yup.object({
      group_name: Yup.string().when('isDefault', {is: true, then: Yup.string().required('Please enter name of the group'), otherwise: Yup.string()}),
      userId: Yup.string().required("Please enter user id"),
      password: Yup.string().min(5, "Password should be at least 5 characters long").required("Please enter password"),
      retype: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: values => {
      var val = {
        group_name: values.group_name,
        userId: values.userId,
        password: values.password,
      }
      onSave(val)
    },
  })

  return (
    <React.Fragment>
      <div className="modal-header">
        {isNew && <h5 className="modal-title mt-0">Add New Credential</h5>}
        {!isNew && <h5 className="modal-title mt-0">Update Credential</h5>}
      </div>
      <Form className="form-horizontal" id="addVarForm">
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Credential Type</Label>
              <select
                id="varname"
                name="type"
                className="form-control"
                onChange={handleTypeChange}
                onBlur={validation.handleBlur}
                value={isDefault}
                disabled={!isNew}
               
              >
                <option value={true}>Default</option>
                <option value={false}>Group</option>
                </select>

          </div>
          {!isDefault &&<div className="mb-3">
            <Label className="form-label">Group Name</Label>
            <Input
              id="varname"
              name="group_name"
              className="form-control"
              placeholder="Enter name of the group"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.group_name || ""}
              invalid={
                validation.touched.group_name && validation.errors.group_name
                  ? true
                  : false
              }
            />
            {validation.touched.group_name && validation.errors.group_name ? (
              <FormFeedback type="invalid">
                {validation.errors.group_name}
              </FormFeedback>
            ) : null}
          </div>}
          <div className="mb-3">
            <Label className="form-label">User Id</Label>
            <Input
              id="userId"
              name="userId"
              className="form-control"
              placeholder="Enter the user id"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.userId || ""}
              invalid={
                validation.touched.userId && validation.errors.userId
                  ? true
                  : false
              }
            />
            {validation.touched.userId && validation.errors.userId ? (
              <FormFeedback type="invalid">
                {validation.errors.userId}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Password</Label>
            <Input
              id="password"
              name="password"
              className="form-control"
              placeholder="Enter the password"
              type="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password || ""}
              invalid={
                validation.touched.password && validation.errors.password
                  ? true
                  : false
              }
            />
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">
                {validation.errors.password}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Confirm Password</Label>
            <Input
              id="retype"
              name="retype"
              className="form-control"
              placeholder="Retype the password"
              type="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.retype || ""}
              invalid={
                validation.touched.retype && validation.errors.retype
                  ? true
                  : false
              }
            />
            {validation.touched.retype && validation.errors.retype ? (
              <FormFeedback type="invalid">
                {validation.errors.retype}
              </FormFeedback>
            ) : null}
          </div>
        </div>

        <div className="modal-footer">
          <button
            id="addValButton"
            type="button"
            className="btn btn-primary"
            onClick={() => validation.handleSubmit()}
          >
            Save
          </button>{" "}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default GroupCredForm
