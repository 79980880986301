import QdContainer from 'components/Common/QdContainer'
import React, { useState } from 'react'
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import OIDCDetails from './OIDCDetails'
import classes from './AuthGrantFlowDetails.scss'
import classnames from "classnames";
import SamlDetails from './SamlDetails'


const AuthGrantFlowDetails = (props) => {
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab);
        }
      };

    return (


        <Card className='p-3'>
            <h6 className='text-primary'>Auth Configuration - Auth grant flow</h6>
            <CardBody>
                <div>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">OIDC</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">SAML</span>
                            </NavLink>
                        </NavItem>


                    </Nav>

                    <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                    >
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <OIDCDetails />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <span><SamlDetails /></span>
                                </Col>
                            </Row>
                        </TabPane>



                    </TabContent>
                </div>


            </CardBody>
        </Card>

    )


}

export default AuthGrantFlowDetails

