import React, { useEffect, useState } from "react"
import Timeline from "./Timeline"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  InputGroup,
  Label,
  Modal,
  Row,
} from "reactstrap"
import classes from "./Temp.scss"
import RelatedOrders from "./RelatedOrders"
import LoadingOverlay from "react-loading-overlay"

const Temp = props => {
  let row = { ...props.data }
  const api_url = process.env.REACT_APP_API_URL

  const [data, setData] = useState([])
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    fetchData(row)
  }, [])

  const fetchData = async row => {
    setLoading(true)
    try {
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const loadEmailContent = async status => {
    try {
      setLoading(true)
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url +
          "stop/timeline/" +
          row.original.stopId +
          "/" +
          "email-content/" +
          status.moredetails.id,
        { headers }
      ) // Replace with your API endpoint

      if (!response.ok) {
        throw new Error("Request failed")
      }

      let data = await response.text()
      setEmailContent(data)
      console.log(data)
      setLoading(false)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  function tog_center(status) {
    setmodal_center(!modal_center)
  }

  const handleFromDate = e => {
    var d = new Date(e)
    var fromDateStr = moment(d).format("yyyy-MM-DD")
    setStartDate(fromDateStr)
  }

  const confirm = () => {
    setShowConfirm(!showConfirm)
  }

  const confirmAppt = async() => {
    debugger
    try {
      setLoading(true)
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }
      const response = await fetch(
        api_url +
          "appointment/accept/" +
          row.original.stopId,
        { headers }
      ) // Replace with your API endpoint

      if (!response.ok) {
        throw new Error("Request failed")
      }

      setLoading(false)
      setShowConfirm(false)
      props.refreshTable()
      
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
      setShowConfirm(false)
    }


  }

  return (
    <>
      <Modal
        isOpen={showConfirm}
        toggle={() => {
          confirm(null)
        }}
        centered
        size="lg"
      >
        <LoadingOverlay active={loading} spinner>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => {
                setShowConfirm(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => setShowConfirm(false)}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body">
            Are you sure you want to accept the appiontment scheduled on{" "}
            <span className="text-primary">
              {row.original.scheduledAppointmentDate} @{" "}
              {row.original.scheduledAppointmentTime}
            </span>
            ?
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                confirmAppt()
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-secodnary"
              onClick={() => setShowConfirm(false)}
            >
              No
            </button>
          </div>
        </LoadingOverlay>
      </Modal>
      {loading && <div>Loading....</div>}
      {!loading && (
        <div className="flex">
          <Row className="w-100">
            <Col md="4">
              <Card outline color="primary" className="border mt-3">
                <CardBody className="p-1 cardBody">
                  <Timeline data={row} />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Row className="mt-0">
                <Card outline color="primary" className="border mt-3 mb-3">
                  <CardBody className="p-1 cardBody">
                    <Row className="mb-2 font-weight-100">
                      <Col md="4" className={"text-muted"}>
                        <span>Location Info:</span>
                      </Col>
                      <Col md="8">
                        <span>{row.original.tmsLocationName}</span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md="4" className={"text-muted"}>
                        <span>Miles from previous stop:</span>
                      </Col>
                      <Col md="8">
                        <span>{row.original.milesFromPreviousStop}</span>
                      </Col>
                    </Row>
                    {row.original.exceptionReason !== null && (
                      <Row className="mb-2">
                        <Col md="4" className={"text-muted"}>
                          <span>Exception Reason:</span>
                        </Col>
                        <Col md="8" className={"text-danger"}>
                          <span>{row.original.exceptionReason}</span>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Row>
              <Row>
                <RelatedOrders data={row.original} />
              </Row>
            </Col>
            {row.original.exceptionReason &&
                      row.original.exceptionReason.includes(
                        "Appointment confirmed, but"
                      ) &&<Col md="2">
              <div className="clearfix mt-3 pr-1">
                <Dropdown
                  isOpen={settingsMenu}
                  toggle={() => {
                    setSettingsMenu(!settingsMenu)
                  }}
                  className="float-end"
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-primary btn-sm"
                  >
                    Actions <i className="bx bx-caret-down"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/* <DropdownItem href="#">Cancel</DropdownItem>
                    {row.original.apppointmentStatus === "Confirmed" ? (
                      <DropdownItem href="#">Reschedule</DropdownItem>
                    ) : row.original.apppointmentStatus ===
                      "Waiting For Approval" ? (
                      <DropdownItem onClick={tog_center}>Approve</DropdownItem>
                    ) : (
                      <DropdownItem href="#">Schedule</DropdownItem>
                    )} */}
                     
                        <DropdownItem href="#" onClick={confirm}>
                          Accept appointment
                        </DropdownItem>
                     
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>}
            {/* Modal content*/}
            <Modal
              isOpen={modal_center}
              toggle={() => {
                tog_center(null)
              }}
              centered
              size="md"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Approve</h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_center(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    onClick={() => {
                      setmodal_center(false)
                    }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                }}
              >
                <div className="modal-body">
                  <div className="form-group mb-4">
                    <Label>From Date</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                        }}
                        onChange={handleFromDate}
                        // value={fromDate}
                      />
                    </InputGroup>
                  </div>
                  <div className="form-group mb-0">
                    <label>Specify a step for the minute field</label>

                    <div className="input-group">
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Select time"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="mdi mdi-clock-outline" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Approve
                  </button>
                </div>
              </Form>
            </Modal>
          </Row>
        </div>
      )}
    </>
  )
}

export default Temp
