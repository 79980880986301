import React from 'react'
import LoadingOverlay from 'react-loading-overlay'

const PublishTempalteBox = (props) => {

    return (
        <LoadingOverlay active={props.loading} spinner>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Confirmation</h5>
            <button
              type="button"
              onClick={() => props.setShowPublishConfirm(false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => props.setShowPublishConfirm(false)}>
                &times;
              </span>
            </button>
          </div>
    
          <div className="modal-body">
            Are you sure you want to publish the template{" "}
            <span className="text-primary">
              {props.template.name}
            </span>{" "}
            ?
          </div>
    
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => props.publish()}
            >
              Publish
            </button>
            <button
              type="button"
              className="btn btn-secodnary"
              onClick={() => props.setShowPublishConfirm(false)}
            >
              Cancel
            </button>
          </div>
        </LoadingOverlay>
      )
}

export default PublishTempalteBox