import React from "react"
import { Provider } from "./Creds"
import CredList from "./CredList"
import QdContainer from "components/Common/QdContainer"
import Header from "./Header"

function ApptSysCreds() {
  return (
    <Provider>
      <QdContainer>
        <CredList />
      </QdContainer>
    </Provider>
  )
}

export default ApptSysCreds
