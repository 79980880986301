import React, { createContext, useState } from "react"
import axios from "axios"

const CredContext = createContext()

function Provider({ children }) {
  const api_url = process.env.REACT_APP_API_URL

  const [creds, setCreds] = useState([])
  const [apptSysTypes, setApptSysTypes] = useState([])
  const [audits, setAudits] = useState([])
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [msg, setMsg] = useState()
  const url = api_url + "appt-sys"

  const fetchCreds = async () => {
    setLoading(true)
    const token = localStorage.getItem("idToken")
    const headers = { Authorization: `Bearer ${token}` }
    const response = await axios.get(url, {
      headers,
    })

    if (response.status == 200) {
      setLoading(false) 
      if (response.data.length > 0) {
        let result = response.data?.sort((a, b) => a.appt_sys.localeCompare(b.appt_sys))
        setCreds(result)
      } 

    } else if ([401, 403].includes(response.status)) {
        setLoading(false)
    }
    else {
      setLoading(false)
    }
  }

  const fetchAllApptSys = async() => {
    setFormLoading(true)
    const token = localStorage.getItem("idToken")
    const headers = { Authorization: `Bearer ${token}` }
    const response = await axios.get(url + '/all', {
      headers,
    })

    if (response.status == 200) {
        setFormLoading(false)
      setApptSysTypes(response.data)
    } else {
        setFormLoading(false)
    }
  }

  const create = async obj => {
    setFormLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await axios.post(url, obj, { headers })

      if (response.status >= 200 && response.status < 300) {
        setFormLoading(false)
        //setLoading(false);
        return { data: response.data, error: null };
      } 
    } catch (e) {

    console.log(e)
      setFormLoading(false)
      return { data: null, error: e.response.data.message };
    }
  }

  const update = async obj => {
    setFormLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await axios.put(url, obj, { headers })

      if (response.status >= 200 && response.status < 300) {
        setFormLoading(false)
        return { data: response.data, error: null };
      }
    } catch (e) {
        setFormLoading(false)
        return { data: null, error: e.response.data.message };
    }
  }

  const fetchAuditHistory = async m_key => {
    let obj = {
      m_key: m_key,
    }
    console.log("Object in uipdate", obj)
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      const response = await axios.post(url + "/audits", obj, { headers })

      if (response.status >= 200 && response.status < 300) {
        setAudits(response.data)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const valueToShare = {
    creds,
    loading,
    fetchCreds,
    create,
    update,
    fetchAuditHistory,
    audits,
    success,
    setSuccess,
    formLoading,
    apptSysTypes,
    fetchAllApptSys,
    error,
    msg
  }

  return (
    <CredContext.Provider value={valueToShare}>{children}</CredContext.Provider>
  )
}

export { Provider }
export default CredContext
