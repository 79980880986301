import React, { useContext, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import CredDetails from './CredDetails'
import CredContext from './Creds'
import Header from './Header'

function ApptSysCards( ) {

    const [showCredDetails, setShowCredDetails] = useState(false)
    const [apptSystem, setApptSystem] = useState()
    const {creds} = useContext(CredContext)

    const handleCredClick = (apptSys) => {
        setApptSystem(apptSys)
        setShowCredDetails(true)
    }

    const handleCancel = () => {
        setShowCredDetails(false)
        setApptSystem(null)
    }
    

    return (
        <>
            {!showCredDetails && <div className='d-flex mt-1 flex-wrap'>
                {creds.map((item, index) => (
                    <Col lg={3} md={3} key={index} className='p-2 pointer'>
                        <Card className={"bg-white glow-card pt-1 pb-1 "} onClick={() => handleCredClick(item)}>
                            <CardBody className="pt-1 pb-1">
                                <Row>
                                    <Col>
                                        <div className={"mt-2 d-flex justify-content-left text-primary "}>
                                            <h5 className={"text-primary"}>{item.appt_sys}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='mt-2 text-paragraph'>
                                    {'Click to get details'}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                ))}
                {/* </Row> */}
            </div>}
            {showCredDetails && <CredDetails apptSys = {apptSystem} onCancel={handleCancel}/>}

        </>
    )


}

export default ApptSysCards