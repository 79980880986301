import React from 'react'
import Cards from './Cards'

const SectionDetails = (props) => {

    const selectCard = (card) => {
        props.setCard(card, props.data.title)
    }

    const reset = () => {
        props.resetCard()
    }

    return (
        <React.Fragment>
        <h5 >{props.data.title}</h5>
            {props.data?.card?.length > 0 && <Cards data={props.data.card} sectionName = {props.data.title} selectCard={selectCard} resetCard = {reset}/>}
        </React.Fragment>
        
    )
}

export default SectionDetails