import React, { useEffect, useState } from 'react'
import EditForm from './EditForm'
import QdContainer from 'components/Common/QdContainer'
import { useNavigate, useParams } from 'react-router-dom'

const CloneTemplate = (props) => {

    const { name } = useParams('name')
    const [loading, setLoading] = useState(false)
    const [templateData, setTemplateData] = useState()

    const api_url = process.env.REACT_APP_API_URL

    const navigate = useNavigate()

    const cancel= () => {
        navigate("/email-template")
    }

    useEffect(() => {
        fetchData()

    }, [])

    const fetchData = async () => {
        setLoading(true)
        try {
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }

            var url = api_url

            url = url + "email/templates/" + name

            const response = await fetch(url, { headers })

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            setTemplateData(jsonData)
            setLoading(false)
        } catch (error) {
            console.error("Error:" + error)
            setLoading(false)
            console.error(error)
        }
    }

    return (
        <QdContainer>
            {loading && <div>Loading...</div>}
            {!loading && templateData && <EditForm data={templateData} isClone={true} cancel={cancel}/>}
        </QdContainer>

    )
}

export default CloneTemplate