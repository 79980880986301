import QdContainer from 'components/Common/QdContainer'
import React from 'react'
import AuthGrantFlow from './AuthGrantFlow'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const AuthorizationConfig = () => {
    document.title = 'Auth Configuration | Qued Portal'

    const navigate = useNavigate()

    const gotoAuthGrantFlow = () => {
        navigate("/auth-config/code")
    }

    const gotoClientCredsFlow = () => {
        navigate("/auth-config/clientcred")
    }

    return (
        <QdContainer>
            <div className='d-flex justify-content-center'>
                <Col sm={3}>
                <div className='m-2'>
                    <Card className={"bg-white glow-card pt-1 pb-1 pointer"} id="newRequestCard" onClick={gotoAuthGrantFlow}>
                        <CardBody className=" pb-1 p-4">
                            <div className="d-flex justify-content-center mb-1 text-primary">
                                <h5 className={"text-primary"}>Authorisation Code Grant Flow</h5>

                            </div>
                            <div className={"mt-1  text-muted pointer text-center"}>
                                Confiugration for user authentication based on Authorisation code grant flow.

                            </div>
                        </CardBody>
                    </Card>
                </div>
                </Col>
                
                <Col sm={3}>
                <div className='m-2'>
                    <Card className={"bg-white glow-card pt-1 pb-1 pointer"} id="newRequestCard" onClick={gotoClientCredsFlow}>
                        <CardBody className=" pb-1 p-4">
                            <div className="d-flex justify-content-center mb-1 text-primary">
                                <h5 className={"text-primary"}>Client Credential Flow</h5>

                            </div>
                            <div className={"mt-1  text-muted pointer text-center"}>
                                Confiugration for service authentication based on client credentials 

                            </div>
                        </CardBody>
                    </Card>
                </div></Col>

            </div>
        </QdContainer>
    )
}

export default AuthorizationConfig