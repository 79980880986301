import { useFormik } from "formik"
import React, { useState } from "react"
import { Col, Form, FormFeedback, Input, Label, Row, UncontrolledAlert } from "reactstrap"
import * as Yup from "yup"

const EmailModal = (props) => {
  const [email, setEmail] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      console.log(values.email)
      props.addEmail(values.email)
    },
  })

  const closeMe = () => {
    props.closeEmailModal()
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Email</h5>
      </div>
      <Form className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            //return false
          }}>
      <div className="modal-body">
       
          
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              className="form-control"
              placeholder="Enter email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
            
          </div>
          
       
      </div>

      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-primary"
        >
          Ok
        </button>
{" "}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeMe}
        >
          Cancel
        </button>
      </div>
      </Form>
    </React.Fragment>
  )
}

export default EmailModal
