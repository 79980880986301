import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import GroupCred from './GroupCred';

function CredData({ apptSys, onCancel, onEdit }) {

  const[creds, setCreds] = useState([])

  const setCredentials = (creds) => {
    setCreds(creds)
  }

  useEffect(() => {
    const result = [
      {
        group_name: "default",
        userId: apptSys.creds?.userId,
        password: apptSys.creds?.password
      },
      ...Object.entries(apptSys.creds).filter(([key, value]) => typeof value === 'object' && value !== null).map(([group_name, credentials]) => ({
        group_name,
        ...credentials
      }))
    ];

    setCreds(result)
  }, [])

  return (
    <Card>
      {creds.length > 0 &&<CardBody>
        <Row className='mb-4'>
          <Col><h5 className='text-primary'>Credential Details</h5></Col>
          <Col><div className="flex text-end me-3"><i className="mdi mdi-pencil font-size-16 text-primary me-1 pointer" onClick={onEdit}/></div></Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <span className="text-muted">Appointment System:</span>
          </Col>
          <Col md={9}>
            <span>{apptSys.appt_sys}</span>
          </Col>
        </Row>
        
        <GroupCred creds={creds} setCreds={setCredentials}/>

        <div className="modal-footer">
          <Button
            color="secondary"
            className="btn btn-sm btn-soft"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </CardBody>}
    </Card>
  )
}

export default CredData
