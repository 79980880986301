import React, { useContext } from "react"
import CredContext from "./Creds"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"

function Header({onCreate}) {
  const { fetchCreds } = useContext(CredContext)

  return (
    <Card>
      <CardBody>
        <Col>
          <div className="float-start">
            <h5 className="text-primary">Appointment System Credentials</h5>
          </div>
        </Col>
        <Col>
          <div className="float-end">
            <div className="d-flex gap-2">
              <div className="text-sm">
              <span style={{ marginRight: "20px" }}>
                        <Link  onClick={onCreate}>
                          <i className="fas fa-user-plus" />
                        </Link>
                      </span>
                <span
                  onClick={
                    fetchCreds
                  }
                  className="pointer text-primary"
                >
                  <i className="fas fa-sync-alt" />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </CardBody>
    </Card>
  )
}

export default Header
