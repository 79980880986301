import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Form, FormFeedback, Input, Label } from "reactstrap"

function EmailGroupEditForm({
  closeModal,
  onSave,
  groupData,
  groups,
  isNew,
  isEdit,
}) {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: groupData ? groupData.groupName : "",
      to: groupData?.to || "",
      cc: groupData?.cc || "",
    },
    validationSchema: Yup.object({
      groupName: Yup.string().required("Please enter name of the group"),
    }),
    onSubmit: values => {
      var val = {
        groupName: values.groupName,
        to: values.to,
        cc: values.cc,
      }
      onSave(val)
    },
  })

  return (
    <React.Fragment>
      <div className="modal-header">
        {isNew && <h5 className="modal-title mt-0">Add New Group</h5>}
        {!isNew && <h5 className="modal-title mt-0">Update Group</h5>}
      </div>
      <Form className="form-horizontal" id="addVarForm">
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Group Name</Label>
            <Input
              id="groupName"
              name="groupName"
              className="form-control"
              placeholder="Enter name of the group"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.groupName || ""}
              invalid={
                validation.touched.groupName && validation.errors.groupName
                  ? true
                  : false
              }
            />
            {validation.touched.groupName && validation.errors.groupName ? (
              <FormFeedback type="invalid">
                {validation.errors.group_name}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">To</Label>
            <textarea
              id="to"
              name="to"
              className="form-control"
              placeholder="Enter comma separated to list"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.to || ""}
            />
            {validation.touched.to && validation.errors.to ? (
              <FormFeedback type="invalid">{validation.errors.to}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Cc</Label>
            <textarea
              id="cc"
              name="cc"
              className="form-control"
              placeholder="Enter comma separated CC list"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.cc || ""}
            />
            {validation.touched.cc && validation.errors.cc ? (
              <FormFeedback type="invalid">{validation.errors.cc}</FormFeedback>
            ) : null}
          </div>
        </div>

        <div className="modal-footer">
          <button
            id="addValButton"
            type="button"
            className="btn btn-primary"
            onClick={() => validation.handleSubmit()}
          >
            Save
          </button>{" "}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default EmailGroupEditForm
