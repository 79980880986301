import React, { useState, useEffect } from "react";
import { AccountContext } from "pages/Auth/Account";
import { useNavigate } from "react-router-dom";
import Pool from "UserPool";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import person from "../../../assets/images/users/person3.png"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const navigate = useNavigate();

  const logout =() => {
    debugger
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    } 
    localStorage.removeItem("email");
    localStorage.removeItem("idToken")
    localStorage.removeItem("user")
    localStorage.removeItem("profilePic")
    localStorage.removeItem("tenantId")
    var logoutUrl = localStorage.getItem("logoutUrl")
    if(logoutUrl) {
      localStorage.removeItem("logoutUrl")
      window.location=logoutUrl
    } else {
      navigate("/");
    }
    
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <div style={{'backgroundImage': `{localStorage.getItem("profilePic")}`}} className="rounded-circle header-profile-user">

          </div> */}
          <img
            className="header-profile-user"
            src={person}
            alt="Header Avatar"
          />

          <span className="d-none d-xl-inline-block ms-2 me-1">{localStorage.getItem("user")}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="dropdown-item" onClick={logout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
