import React, { useEffect } from "react"
import LoadingOverlay from "react-loading-overlay"

const MapTemplateBox = props => {
  useEffect(() => {
    console.log(props.selectedLoc)
  }, [])

  return (
    <LoadingOverlay active={props.loading} spinner>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmation</h5>
        <button
          type="button"
          onClick={() => props.setShowPublishConfirm(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span
            aria-hidden="true"
            onClick={() => props.setShowPublishConfirm(false)}
          >
            &times;
          </span>
        </button>
      </div>

      <div className="modal-body">
        Are you sure you want to map the following selected locations to the
        template <span className="text-primary">{props.tempalteName}</span> ?
        <ul>
          {props.selectedLoc.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.executeMap()}
        >
          Yes
        </button>
        <button
          type="button"
          className="btn btn-secodnary"
          onClick={() => props.setShowPublishConfirm(false)}
        >
          No
        </button>
      </div>
    </LoadingOverlay>
  )
}

export default MapTemplateBox
