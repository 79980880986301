import React, { useEffect, useState } from "react"
import DetailReference from "./DetailsReference"

const ClippedText = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
 
  return (
    <div>
      <p style={{marginBottom:'0px'}}>
        {isExpanded ? props.text : props.text?.slice(0, props.maxLength)}
        {props.text?.length > props.maxLength && !isExpanded && <span>...</span>}
      </p>
        
    </div>
  )
}

export default ClippedText;
