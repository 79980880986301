import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  Row,
} from "reactstrap"
import CredContext from "./Creds"
import { useFormik } from "formik"
import * as Yup from "yup"
import LoadingOverlay from "react-loading-overlay"
import GroupCred from "./GroupCred"
import Success from "pages/NotificationModal/Success"
import Error from "pages/NotificationModal/Error"

function CredForm({ appt_sys, isEdit, onCancel }) {
  const {
    fetchAllApptSys,
    formLoading,
    create,
    update,
    fetchCreds,
    success,
    setSuccess,
    apptSysTypes,
    error,
    msg,
  } = useContext(CredContext)

  const [apptSysName, setApptSysName] = useState(appt_sys?.appt_sys || null)

  const [isGroupCred, setIsGroupCred] = useState(
    appt_sys?.has_cred_group || false
  )

  const [creds, setCreds] = useState([])

  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(0)

  useEffect(() => {
    fetchAllApptSys()
    if (appt_sys && appt_sys.creds) {
      console.log("apptSys in cred data --> ", appt_sys)
      console.log(appt_sys.creds)
      const result = [
        {
          group_name: "default",
          userId: appt_sys.creds?.userId,
          password: appt_sys.creds?.password,
        },
        ...Object.entries(appt_sys.creds)
          .filter(([key, value]) => typeof value === "object" && value !== null)
          .map(([group_name, credentials]) => ({
            group_name,
            ...credentials,
          })),
      ]
      setCreds(result)
    }
  }, [])

  console.log(apptSysTypes)

  const options = [
    {
      label: "RETALIX",
      value: "RETALIX",
    },
    {
      label: "OPENDOCK",
      value: "OPENDOCK",
    },
    { label: "WALMART_APP_SYS_RL", value: "WALMART_APP_SYS_RL" },
    { label: "RETAILLINK", value: "RETAILLINK" },
  ]

  const handleSelectGroup = event => {
    setApptSysName(event.target.value)
  }

  const save = async () => {
    if (!apptSysName) {
      setShowError(true)
      setErrorMsg("Please select appointment system")
      return
    }
    var obj = {
      appt_sys: apptSysName,
      createdBy: localStorage.getItem("email"),
    }

    const result = creds.reduce((acc, group) => {
      if (group.group_name === "default") {
        acc.userId = group.userId
        acc.password = group.password
      } else {
        acc[group.group_name] = {
          userId: group.userId,
          password: group.password,
        }
      }
      return acc
    }, {})

    obj = { ...obj, attribs: result }

    console.log(obj)
    let response
    if (isEdit) {
      var newObj = { ...obj, m_key: appt_sys.m_key }
      response = await update(newObj)
    } else {
      response = await create(obj)
    }
    if (response.data) {
      setShowSuccess(true)
    }
    if (response.error) {
      setShowError(true)
      setErrorMsg(response.error)
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      apptSysName: appt_sys?.appt_sys || null,
      has_cred_group: appt_sys?.has_cred_group || false,
      userId: appt_sys?.creds.userId || "",
      password: appt_sys?.creds.password || "",
      retype: appt_sys?.creds.password || "",
    },
    validationSchema: Yup.object({
      apptSysName: Yup.string().required("Please Enter Your first name"),
      userId: Yup.string().required("Please Enter user id"),
      password: Yup.string()
        .min(5, "Password must be at least 5 characters long")
        .required("Please enter password"),
      retype: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),

    onSubmit: values => {
      save()
    },
  })

  const togSuccess = () => {
    setShowSuccess(!showSuccess)
  }

  const closeSuccess = () => {
    setSuccess(false)
    fetchCreds()
  }

  const togError = () => {
    setShowError(!showError)
  }

  return (
    <>
      <Modal isOpen={showSuccess} toggle={togSuccess}>
        <Success
          message="Credential saved successfully !"
          close={closeSuccess}
        />
      </Modal>
      <Modal isOpen={showError} toggle={togError}>
        <Error message={errorMsg} closeModal={togError} />
      </Modal>
      {apptSysTypes.length > 0 && (
        <Card>
          <CardBody>
            <LoadingOverlay active={formLoading} spinner>
              {isEdit && (
                <h5 className="mb-4 text-primary">Update Credential</h5>
              )}
              {!isEdit && (
                <h5 className="mb-4 text-primary">Create New Credential</h5>
              )}
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  save()
                  return false
                }}
              >
                <Row className="mb-4">
                  <Col md={4}>
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className=" col-form-label text-align-right"
                    >
                      Appointment System
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <select
                      value={apptSysName}
                      disabled={isEdit}
                      name="apptSysName"
                      className="form-control"
                      onChange={e => {
                        handleSelectGroup(e)
                      }}
                    >
                      <option value={null}>Select</option>
                      {apptSysTypes.map((item, index) => {
                        return (
                          <option value={item.key} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  </Col>
                </Row>

                <GroupCred creds={creds} isEdit={true} setCreds={setCreds} />

                <div className="modal-footer">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn btn-sm me-3"
                    
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className="btn btn-secondary btn-sm ml-3"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </LoadingOverlay>
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default CredForm
