import { Box } from "@mui/material"
import PendingReqScheduleTableContainer from "components/Common/PendingReqScheduleTableContainer"
import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import ApptReqDetails from "./ApptReqDetails"
import CancelConfirm from "./CancelConfirm"

const ReqScheduler = () => {
  document.title = "Pending Req Schdule | Qued Portal"
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  const info = row => {
    console.log("info.... ")
    setShowDetailsModal(true)
    console.log(row)
  }

  const confirmCancel = row => {
    console.log("Confirming cancellation of appt request")
    setShowCancelConfirm(true)
    setCurrentRow(row.original)

  }

  const getColumns = () => {
    return useMemo(
      () => [
        {
          accessor: "stopIdList",
          Header: "Stop Ids",
        },
        {
          accessor: "apptSysType",
          Header: "Appointment System",
        },
        {
          accessor: "reqEvent.tmsStopLocId",
          Header: "Location Id",
        },
        {
          accessor: "status",
          Header: "Status",
        },
        {
            accessor: 'reqTime',
            Header: "Requested Time"
        },
        {
            accessor: 'reqEvent.apptInfo.apptDttm',
            Header: "Appointment Date"
        },
        {
          Header: "Action",
          Cell: ({ row }) => (
            <Box style={{ width: "100%" }}>
              <Col className="d-flex justify-content-center">
                <>
                  {/* <i
                    className="mdi mdi-information-variant d-block font-size-16 text-primary"
                    id={"edittooltip" + row.id}
                    onClick={() => info(row)}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"edittooltip" + row.id}
                  >
                    More info
                  </UncontrolledTooltip> */}
                  &nbsp; &nbsp;&nbsp; &nbsp;
                  {/* {props.value && props.value.length > 0 && (
                  <i
                    className="fas fa-trash-alt d-block font-size-16 text-red"
                    id={"deletetooltip" + props.row.id}
                    // onClick={() => showConfirm()}
                  />
                )}
                {props.value && props.value.length > 0 && (
                  <UncontrolledTooltip
                    placement="top"
                    target={"deletetooltip" + props.row.id}
                  >
                    Delete
                  </UncontrolledTooltip>
                )}
                {!props.value && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>} */}
                  {row.original.status != "CANCELLED" && (
                    <i
                      className="mdi mdi-close-thick d-block font-size-16 text-primary"
                      id={"deletetooltip" + row.id}
                      onClick={() => confirmCancel(row)}
                    />
                  )}
                  {row.original.status != "CANCELLED" && (
                  <UncontrolledTooltip
                    placement="top"
                    target={"deletetooltip" + row.id}
                  >
                    Cancel appointment request
                  </UncontrolledTooltip>
                )}
                </>
              </Col>
            </Box>
          ),
        },
      ],
      []
    )
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url

      url = url + "appointment/req-scheduler"

      const response = await fetch(url, { headers })

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      setData(jsonData.content)
      console.log(data)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error)
    }
  }

  const columns = getColumns()

  const refreshTable = () => {
    fetchData()
  }

  function tog_center() {
    setShowCancelConfirm(false)
    setShowDetailsModal(false)
  }

  const closeCancelModal = () => {
    setShowCancelConfirm(false); 
    setCurrentRow(null); 
    fetchData()
  }

  return (
    <React.Fragment>
        <Modal
        isOpen={showDetailsModal}
        toggle={() => {
          tog_center()
        }}
        centered
        size="lg"
      >
        <ApptReqDetails setShowDetailsModal={setShowDetailsModal}/>
      </Modal>
      <Modal
        isOpen={showCancelConfirm}
        toggle={() => {
          tog_center()
        }}
        centered
        size="md"
      >
        <CancelConfirm closeModal={closeCancelModal} data={currentRow}/>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <PendingReqScheduleTableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={false}
                    isAddOptions={true}
                    customPageSize={50}
                    loading={loading}
                    refreshTable={refreshTable}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReqScheduler
