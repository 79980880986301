import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
  UncontrolledAlert,
} from "reactstrap"
import LoadingOverlay from "react-loading-overlay"
import { useNavigate } from "react-router-dom"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Success from "pages/NotificationModal/Success"

const CreateUser = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [toast, setToast] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [toasttype, setTotasttype] = useState("")
  const navigate = useNavigate()
  const api_url = process.env.REACT_APP_API_URL

  
  const save = async values => {
    setLoading(true)
    console.log(values)
    try {
      const token = localStorage.getItem("idToken")
      var url = api_url

      url = url + "users"
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        setError(res.error)
        setLoading(false)
        throw new Error(res.error)
      }
      debugger
      setLoading(false)
      //setTotasttype("Success")
      setShowModal(true)
    } catch (error) {
      console.error("Error:" + error.message)
      console.error(error.message)
      setLoading(false)
    }
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your first name"),
      lastName: Yup.string().required("Please Enter Your last name"),
      email: Yup.string().required("Please enter valid email ID"),
    }),

    onSubmit: values => {
      save(values)
    },
  })

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="sm"
      >
        <Success message='User created successfully !!' redirect='/user-admin'/>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <LoadingOverlay active={loading} spinner>
                <CardTitle className="mb-4">Create New User</CardTitle>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      First name
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="horizontal-firstname-Input"
                        placeholder="Enter Your first name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Last name
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="horizontal-lastname-Input"
                        placeholder="Enter Your last name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-email-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Email
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="email"
                        className="form-control"
                        id="horizontal-email-Input"
                        name="email"
                        placeholder="Enter Your Email ID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Label
                      htmlFor="horizontal-email-Input"
                      className="col-sm-3 col-form-label"
                    ></Label>

                    {error != null && (
                      <Col sm={6}>
                        <div className="mb-3 mt-3">
                          <UncontrolledAlert color="danger" role="alert">
                            {error}
                          </UncontrolledAlert>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="justify-content-end">
                    <Col sm={9}>
                      <div>
                        <Button type="submit" color="primary" className="w-md">
                          Submit
                        </Button>
                        {"     "}
                        <Button
                          type="button"
                          color="secondary"
                          className="w-md"
                          onClick={() => {
                            navigate("/user-admin")
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </LoadingOverlay>
            </CardBody>
          </Card>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateUser
