import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Form, FormFeedback, Input, Label } from 'reactstrap'
import * as Yup from "yup"


const var_types = [
    { label: "PATH", value: "PATH" },
    { label: "FMT", value: "FMT" },
    { label: "EXPR", value: "EXPR" },
    { label: "LIT", value: "LIT" }

]

const VariableForm = (props) => {
    const [varData, setVarData] = useState(props.varData || null)

    const handleTypeChange = (event) => {
        const { name, value } = event.target;
        var newVar = { ...varData, 'type': value }
        setVarData(newVar)
    }


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

            name: varData?.name || '',
            type: varData?.type || 'PATH',
            value: varData?.value || '',
            notes: varData?.notes || ''

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter name of variable"),
            value: Yup.string().required("Please enter value")
        }),
        onSubmit: values => {
            console.log(values.name)
            var val = {
                'name': values.name,
                'type': values.type,
                'value': values.value,
                'notes': values.notes,
            }
            props.onSave(val)
        },

    })

    return (
        <React.Fragment>
            <div className="modal-header">
                {props.isNew && <h5 className="modal-title mt-0">Add New Variable</h5>}
                {!props.isNew && <h5 className="modal-title mt-0">Update Variable</h5>}
            </div>
            <Form className="form-horizontal" id="addVarForm">

                <div className="modal-body">
                    <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                            id="varname"
                            name="name"
                            className="form-control"
                            placeholder="Enter variable name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                                validation.touched.name && validation.errors.name
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.var && validation.errors.var ? (
                            <FormFeedback type="invalid">
                                {validation.errors.var}
                            </FormFeedback>
                        ) : null}

                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Type</Label>
                        <select className="form-control" name="htmlTemplate"
                            onChange={e => {
                                e.preventDefault()
                                handleTypeChange(e)
                            }}
                            value={validation.values.type}

                        >
                            {var_types.map((item, index) => (
                                <option value={item.value} key={index}>{item.label}</option>
                            ))}

                        </select>

                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Value</Label>
                        <Input
                            id="varValue"
                            name="value"
                            className="form-control"
                            placeholder="Enter value"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.value || ""}
                            invalid={
                                validation.touched.value && validation.errors.value
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.value && validation.errors.value ? (
                            <FormFeedback type="invalid">
                                {validation.errors.value}
                            </FormFeedback>
                        ) : null}

                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Notes</Label>
                        <Input
                            id="varValue"
                            name="notes"
                            className="form-control"
                            placeholder="Enter notes"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes || ""}
                            invalid={
                                validation.touched.notes && validation.errors.notes
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.notes && validation.errors.notes ? (
                            <FormFeedback type="invalid">
                                {validation.errors.notes}
                            </FormFeedback>
                        ) : null}

                    </div>

                </div>

                <div className="modal-footer">
                    <button
                        id="addValButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => validation.handleSubmit()}
                    >
                        Save
                    </button>
                    {" "}
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={props.closeModal}
                    >
                        Cancel
                    </button>
                </div>
            </Form>

        </React.Fragment>)

}

export default VariableForm