import React from "react"
import LoadingOverlay from "react-loading-overlay"

const DisableUserBox = props => {
  return (
    <LoadingOverlay active={props.loading} spinner>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirmation</h5>
        <button
          type="button"
          onClick={() => props.setShowDisableModal(false)}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={() => props.setShowDisableModal(false)}>
            &times;
          </span>
        </button>
      </div>

      <div className="modal-body">
        Are you sure you want to <span className="text-danger">disable</span> the user{" "}
        <span className="text-primary">
          {props.user.attributes.given_name} {props.user.attributes.family_name}
        </span>{" "}
        ?
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.disable()}
        >
          Disable
        </button>
        <button
          type="button"
          className="btn btn-secodnary"
          onClick={() => props.setShowDisableModal(false)}
        >
          Cancel
        </button>
      </div>
    </LoadingOverlay>
  )
}

export default DisableUserBox
