import React from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "reactstrap"

const Success = props => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Success</h5>
      </div>

      <div className="modal-body">
        <Row>
          <Col className="d-flex justify-content-center">
            <i className="fas fa-check-double font-size-28 text-success"></i>{" "}
          </Col>
        </Row>
        <Row>
          <Col className="text-center text-success mt-3">
            {props.message}
          </Col>
        </Row>
      </div>

      <div className="modal-footer">
        {props.redirect &&<button
          type="button"
          className="btn btn-success"
          onClick={() => navigate(props.redirect)}
        >
          OK
        </button>}
        {props.close && <button
          type="button"
          className="btn btn-success"
          onClick={props.close}
        >
          OK
        </button>}
      </div>
    </React.Fragment>
  )
}

export default Success
