import { CognitoUserPool } from "amazon-cognito-identity-js";

import { AWS_COGNITO_CONFIG } from 'awsconfig';

// Access Cognito config values
const { userPoolId, clientId, region } = AWS_COGNITO_CONFIG;

const poolData = {
    UserPoolId : userPoolId,
    ClientId : clientId
};

export default new CognitoUserPool(poolData);