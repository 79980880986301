import React from "react"

const ApptReqDetails = (props) => {
  return (
    <>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Appt Request Details</h5>
      <button
        type="button"
        onClick={() => props.setShowDetailsModal(false)}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span
          aria-hidden="true"
          onClick={() => props.setShowDetailsModal(false)}
        >
          &times;
        </span>
      </button>
    </div>
    <div className="modal-body">

    </div>
    </>
  )
}

export default ApptReqDetails
