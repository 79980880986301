import React from "react"
import { Col, Row } from "reactstrap"

const RelatedOrderDetails = ({ data }) => {
  return (
    <Row>
      <Col md={5} >
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span className="">Order #:</span>
          </Col>
          <Col md="8">
            <span>{data.orderNumber}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Qued #:</span>
          </Col>
          <Col md="8">
            <span>{data.quedId}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Org:</span>
          </Col>
          <Col md="8">
            <span>{data.orgId}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Stop Type:</span>
          </Col>
          <Col md="8">
            <span>{data.stopType}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Purchase Order:</span>
          </Col>
          <Col md="8">
            <span>{data.purchaseOrder ? data.purchaseOrder : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Stop Sequence:</span>
          </Col>
          <Col md="8">
            <span>{data.stopSequenceNumber}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Store Number:</span>
          </Col>
          <Col md="8">
            <span>{data.storeNumber ? data.storeNumber : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className={"text-muted"}>
            <span>Location Info:</span>
          </Col>
          <Col md="8">
            <span>{data.tmsLocationName}</span>
          </Col>
        </Row>
       
        
      </Col>
      <Col md={7}>
      <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>References:</span>
          </Col>
          <Col md="7">
            <span style={{overflow:'hidden', wordBreak:'break-all', whiteSpace:'normal'}}>{data.references.join()}</span>
          </Col>
        </Row>
        
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Requested Appointment Date:</span>
          </Col>
          <Col md="7">
            <span>{data.tmsRequestedAppointmentDate ? data.tmsRequestedAppointmentDate : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Requested Appt Date Early:</span>
          </Col>
          <Col md="7">
            <span>{data.tmsRequestedApptDateEarly ? data.tmsRequestedApptDateEarly : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Requested Appt Date Late:</span>
          </Col>
          <Col md="7">
            <span>{data.tmsRequestedApptDateLate ? data.tmsRequestedApptDateLate : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Scheduled Appointment Date:</span>
          </Col>
          <Col md="7">
            <span>{data.scheduledAppointmentDate ? data.scheduledAppointmentDate : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Scheduled Appointment Time:</span>
          </Col>
          <Col md="7">
            <span>{data.scheduledAppointmentTime ? data.scheduledAppointmentTime : '-'}</span>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Appointment Status:</span>
          </Col>
          <Col md="7">
            {data.apppointmentStatus === 'Exception' ? <span className="text-red">{data.apppointmentStatus ? data.apppointmentStatus : '-'}</span> : <span className="text-success">{data.apppointmentStatus ? data.apppointmentStatus : '-'}</span>}
          </Col>
        </Row>

        {data.apppointmentStatus === 'Exception' && <Row className="mb-1">
          <Col md="5" className={"text-muted"}>
            <span>Exception Reason:</span>
          </Col>
          <Col md="7">
            <span className="text-red">{data.exceptionReason ? data.exceptionReason : '-'}</span>
          </Col>
        </Row>}
      </Col>
    </Row>
  )
}

export default RelatedOrderDetails
