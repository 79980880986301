import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import errorSign from '../../assets/images/error-page.png'

const Error = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Card >
                <CardBody >
                    <Row >
                        <Col className="d-flex justify-content-center">
                        <i className="bx bx-error font-size-28 text-primary"></i>{" "}
                        </Col>
                        
                    </Row>
                    <Row className="d-flex justify-content-center">
                        {props.errorMessage}
                    </Row>
                </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Error
