import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Label, Modal, Row } from "reactstrap"
import ClosableTag from "./ClosableTag"
import EmailModal from "./EmailModal"

import classes from "./EditNotification.scss"
import LoadingOverlay from "react-loading-overlay"
import Success from "pages/NotificationModal/Success"

const EditNotification = props => {
  const [action, setAction] = useState()
  const [tos, setTos] = useState([])
  const [defaultTos, setDefaultTos] = useState([])
  const [ccs, setCcs] = useState([])
  const [defaultCcs, setDefaultCcs] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [operationUser, setOperationUser] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)
  const [orderedUser, setOrderedUser] = useState(false)
  const [ccoperationUser, setCcOperationUser] = useState(false)
  const [ccselectedUser, setCcSelectedUser] = useState(false)
  const [ccOrderedUser, setCcOrderedUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    prepareData()
  }, [])

  const prepareData = () => {
    if (props.data) {
      if (props.data.to && props.data.to.length > 0) {
        var tos = props.data.to
          .filter(item => item != "@ENTERED_USER@")
          .filter(item => item != "@OPERATIONS_USER@")
          .filter(item => item != '@ORDEREDBY_USER@')

        var opuser = props.data.to.indexOf("@OPERATIONS_USER@")
        if (opuser > -1) {
          setOperationUser(true)
        }

        var enUser = props.data.to.indexOf("@ENTERED_USER@")
        if (enUser > -1) {
          setSelectedUser(true)
        }

        var orUser = props.data.to.indexOf('@ORDEREDBY_USER@')
        if (orUser > -1) {
          setOrderedUser(true)
        }

        setTos(tos)
      }
      if (props.data.cc && props.data.cc.length > 0) {
        var ccs = props.data.cc
          .filter(item => item != "@ENTERED_USER@")
          .filter(item => item != "@OPERATIONS_USER@")
          .filter(item => item != '@ORDEREDBY_USER@')

        var opuser = props.data.cc.indexOf("@OPERATIONS_USER@")
        if (opuser > -1) {
          setCcOperationUser(true)
        }

        var enUser = props.data.cc.indexOf("@ENTERED_USER@")
        if (enUser > -1) {
          setCcSelectedUser(true)
        }

        var orUser = props.data.cc.indexOf("@ORDEREDBY_USER@")
        if (orUser > -1) {
          setCcOrderedUser(true)
        }

        setCcs(ccs)
      }

      if (props.data.default_to && props.data.default_to.length > 0)
        setDefaultTos(props.data.default_to)
      if (props.data.default_cc && props.data.default_cc.length > 0)
        setDefaultCcs(props.data.default_cc)
    }
  }

  const removeTag = (dataset, index, source) => {
    const newTags = [...dataset]
    newTags.splice(index, 1)
    if (source === "to") setTos(newTags)
    else if (source === "cc") setCcs(newTags)
    else if (source === "default_to") setDefaultTos(newTags)
    else if (source === "default_cc") setDefaultCcs(newTags)
  }

  function tog_center(status) {
    setShowModal(!showModal)

  }

  const addEmail = emailValue => {
    debugger;
    if (action === "to") {
      setTos([...tos, emailValue])
      setShowModal(false)
    } else if (action === "defaultTos") {
      setDefaultTos([...defaultTos, emailValue])
      setShowModal(false)
    } else if (action === "ccs") {
      setCcs([...ccs, emailValue])
      setShowModal(false)
    } else if (action === "defaultCcs") {
      setDefaultCcs([...defaultCcs, emailValue])
      setShowModal(false)
    }
  }

  const save = async () => {
    var newTos = [...tos]
    var newCcs = [...ccs]
    if (operationUser) {
      newTos = [...newTos, "@OPERATIONS_USER@"]
    } else {
      newTos = newTos.filter(item => item != "@OPERATIONS_USER@")
    }

    if (selectedUser) {
      newTos = [...newTos, "@ENTERED_USER@"]
    } else {
      newTos = newTos.filter(item =>  item != "@ENTERED_USER@" )
    }

    if (orderedUser) {
      newTos = [...newTos, "@ORDEREDBY_USER@"]
    } else {
      newTos = newTos.filter(item =>  item != "@ORDEREDBY_USER@")
    }

    if (ccoperationUser) {
      newCcs = [...newCcs, "@OPERATIONS_USER@"]
    } else {
      newCcs = newCcs.filter(item => item != "@OPERATIONS_USER@")
    }

    if (ccselectedUser) {
      newCcs = [...newCcs, "@ENTERED_USER@"]
    } else {
      newCcs = newCcs.filter(item => item != "@ENTERED_USER@")
    }

    if (ccOrderedUser) {
      newCcs = [...newCcs, "@ORDEREDBY_USER@"]
    } else {
      newCcs = newCcs.filter(item =>  item != "@ORDEREDBY_USER@")
    }

    console.log(tos)
    var data = {
      to: newTos,
      default_to: defaultTos,
      cc: newCcs,
      default_cc: defaultCcs,
    }

    try {
      var isNew = props.isNew
      setLoading(true)
      const token = localStorage.getItem("idToken")
      const config = {
        method: isNew ? "POST" : "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(api_url + "notification", config)

      if (!response.ok) {
        throw new Error("Request failed")
      }

      const jsonData = await response.json()
      console.log(jsonData)
      prepareData(jsonData)
      setLoading(false)
      setShowSuccess(true)
    } catch (error) {
      console.error("Error:", error)
      setLoading(false)
    }
  }

  const cancelEdit = () => {
    props.cancelEdit()
  }

  const closeEmailModel = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showModal}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="sm"
      >
        <EmailModal addEmail={addEmail}  closeEmailModal={closeEmailModel}/>
      </Modal>
      <Modal
        isOpen={showSuccess}
        toggle={() => {
          tog_center(null)
        }}
        centered
        size="sm"
      >
        <Success message={"Successfully saved the notification configuration !!"} close={cancelEdit}/>
      </Modal>
      <LoadingOverlay active={loading} spinner>
        <div className="mt-3 p-3">
          <Form>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-email-Input"
                className="col-sm-2 col-form-label"
              >
                To
              </Label>
              <Col sm={10}>
                <Row>
                  <Col className="d-flex ml-2">
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="to-entereduser"
                        checked={selectedUser}
                        onClick={() => {
                          setSelectedUser(!selectedUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="to-outlinecolor1"
                      >
                        Entered User
                      </label>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="cc-operationuser"
                        checked={operationUser}
                        onClick={() => {
                          setOperationUser(!operationUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cc-outlinecolor1"
                      >
                        Operation User
                      </label>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="cc-orderedUser"
                        checked={orderedUser}
                        onClick={() => {
                          setOrderedUser(!orderedUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cc-outlinecolor1"
                      >
                        Ordered User
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex wrapper p-0">
                    {tos?.map((tag, index) => (
                      <ClosableTag
                        showClose={true}
                        key={index}
                        label={tag}
                        onClose={() => removeTag(tos, index, "to")}
                      />
                    ))}

                    <span
                      className="align-item-center p-3 font-size-15 pointer"
                      onClick={() => {
                        setAction("to")
                        setShowModal(true)
                      }}
                    >
                      <i className="mdi mdi-email-plus-outline text-primary"></i>
                    </span>

                    {/* <Button
                    color="primary"
                    className="btn-rounded btn-soft-primary waves-effect waves-light btn-sm "
                    onClick={() => setShowModal(true)}
                  >
                    Add New Email
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-soft-primary waves-effect waves-light"
                    onClick={() => setShowModal(true)}
                  >
                    Primary
                  </Button> */}
                  </div>
                </Row>
              </Col>
            </Row>
            <div className="border-bottom"></div>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-password-Input"
                className="col-sm-2 col-form-label"
              >
                CC
              </Label>
              <Col sm={10}>
                <Row className="p-2">
                  <Col className="d-flex ml-2">
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="cc-entereduser"
                        checked={ccselectedUser}
                        onClick={() => {
                          setCcSelectedUser(!ccselectedUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cc-entereduser"
                      >
                        Entered User
                      </label>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="cc-oerationuser"
                        checked={ccoperationUser}
                        onClick={() => {
                          setCcOperationUser(!ccoperationUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cc-oerationuser"
                      >
                        Operation User
                      </label>
                    </div>

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="cc-oerationuser"
                        checked={ccOrderedUser}
                        onClick={() => {
                          setCcOrderedUser(!ccOrderedUser)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cc-oerationuser"
                      >
                        Ordered User
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex wrapper p-0">
                    {ccs?.map((tag, index) => (
                      <ClosableTag
                        showClose={true}
                        key={index}
                        label={tag}
                        onClose={() => removeTag(ccs, index, "cc")}
                      />
                    ))}

                    <span
                      className="align-item-center p-3 font-size-15 pointer"
                      onClick={() => {
                        setAction("ccs")
                        setShowModal(true)
                      }}
                    >
                      <i className="mdi mdi-email-plus-outline text-primary"></i>
                    </span>
                  </div>
                </Row>
              </Col>
            </Row>
            <div className="border-bottom"></div>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-email-Input"
                className="col-sm-2 col-form-label"
              >
                Default To
              </Label>
              <Col sm={6}>
                <Row>
                  <div className="d-flex wrapper p-0">
                    {defaultTos?.map((tag, index) => (
                      <ClosableTag
                        showClose={true}
                        key={index}
                        label={tag}
                        onClose={() =>
                          removeTag(defaultTos, index, "default_to")
                        }
                      />
                    ))}

                    <span
                      className="align-item-center p-3 font-size-15 pointer"
                      onClick={() => {
                        setAction("defaultTos")
                        setShowModal(true)
                      }}
                    >
                      <i className="mdi mdi-email-plus-outline text-primary"></i>
                    </span>
                  </div>
                </Row>
              </Col>
            </Row>

            <Row className="mb-4">
              <Label
                htmlFor="horizontal-password-Input"
                className="col-sm-2 col-form-label"
              >
                Default CC
              </Label>
              <Col sm={6}>
                <Row>
                  <div className="d-flex wrapper p-0">
                    {defaultCcs?.map((tag, index) => (
                      <ClosableTag
                        showClose={true}
                        key={index}
                        label={tag}
                        onClose={() =>
                          removeTag(defaultCcs, index, "default_cc")
                        }
                      />
                    ))}

                    <span
                      className="align-item-center p-3 font-size-15 pointer"
                      onClick={() => {
                        setAction("defaultCcs")
                        setShowModal(true)
                      }}
                    >
                      <i className="mdi mdi-email-plus-outline text-primary"></i>
                    </span>
                  </div>
                </Row>
              </Col>
            </Row>
            <div className="border-bottom"></div>
            <Row className="justify-content-end mt-3">
              <Col sm={2}></Col>
              <Col>
                <div className="ml-2">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md"
                    onClick={save}
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    type="button"
                    color="secondary"
                    className="w-md"
                    onClick={() => {
                      props.cancelEdit()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </LoadingOverlay>
    </React.Fragment>
  )
}

export default EditNotification
