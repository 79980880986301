import QdContainer from "components/Common/QdContainer"
import React, { useReducer, useState } from "react"
import { Formik, useFormik } from "formik"
import * as Yup from "yup"
import LoadingOverlay from "react-loading-overlay"
import { Button, Card, CardBody, CardTitle, Col, Form, Input, Label, Modal, Row, UncontrolledAlert } from "reactstrap"
import Success from "pages/NotificationModal/Success"

const intialState = {
  loading: false,
  showSuccess: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "togLoading":
      return {
        ...state,
        loading: !state.loading,
      }
    case "togSuccess": {
      return {
        ...state,
        showSuccess: !state.showSuccess,
      }
    }
    default:
      return state
  }
}

const MasterLocation = () => {
  const [state, dispatch] = useReducer(reducer, intialState)
  const [loading, setLoading] = useState(false)
  const api_url = process.env.REACT_APP_API_URL
  const [errorMessage, setErrorMessage] = useState()

  

  const save = async values => {
    dispatch({type:'togLoading'})
    setErrorMessage(null)
    try {
      const token = localStorage.getItem("idToken")
      var url = api_url

      url = url + "location-management/master-locations"
      const config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locationName: values.m_loc_name,
          addr1: values.addr1,
          city: values.city,
          state: values.state,
          zip: values.zip,
          appt_sys_type: values.appt_system_type,
        }),
      }
      const response = await fetch(url, config)

      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        const res = await response.json()
        throw new Error(res.message)
      }
      setErrorMessage(null)
      dispatch({type:'togLoading'})
      dispatch({type: 'togSuccess'})
      
    } catch (error) {
      console.error("Error:" + error)
      setErrorMessage(error.message)
      dispatch({type:'togLoading'})
    }
  }

  const closeSuccess = () => {
    dispatch({type:'togSuccess'})
    validation.resetForm()

  }

  const resetForm = () => {
    validation.resetForm()
    setErrorMessage(null)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      m_loc_name: "",
      addr1: "",
      city: "",
      state: "",
      zip: "",
      appt_system_type: "",
    },
    validationSchema: Yup.object({
      m_loc_name: Yup.string().required("Please Enter location name"),
      addr1: Yup.string().required("Please Enter Address line "),
      appt_system_type: Yup.string().required(
        "Please enter valid appointment system type"
      ),
    }),

    onSubmit: values => {
      save(values)
    },
  })
  return (
    <React.Fragment>
      <Modal
        isOpen={state.showSuccess}
        toggle={() => {
          dispatch({type: 'togSuccess'})
        }}
        centered
        size="sm"
      >
        <Success
          message="Master location created successfully !!"
          close={closeSuccess}
        />
      </Modal>
      <QdContainer>
        <Card>
          <CardBody>
            <LoadingOverlay active={state.loading} spinner>
              <Row>
              <Col>
                <div className="float-start">
                  <h5 className="text-primary">New Master Location</h5>
                </div>
              </Col>

              </Row>

              <Row className="mt-2">
              {errorMessage && (
                        <div className="mb-3">
                          <UncontrolledAlert color="danger" role="alert">
                            {errorMessage}
                          </UncontrolledAlert>
                        </div>
                      )}
              </Row>
             
              <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                  className="p-2"
                >
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Location Name
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="m_loc_name"
                        id="horizontal-firstname-Input"
                        placeholder="Enter location name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.m_loc_name || ""}
                        invalid={
                          validation.touched.m_loc_name &&
                          validation.errors.m_loc_name
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Appointment System Type
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="appt_system_type"
                        id="horizontal-firstname-Input"
                        placeholder="Enter appointment system type "
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.appt_system_type || ""}
                        invalid={
                          validation.touched.appt_system_type &&
                          validation.errors.appt_system_type
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Address Line 1
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="addr1"
                        id="horizontal-firstname-Input"
                        placeholder="Enter address line "
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addr1 || ""}
                        invalid={
                          validation.touched.addr1 &&
                          validation.errors.addr1
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      City
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="city"
                        id="horizontal-firstname-Input"
                        placeholder="Enter city "
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.city || ""}
                        invalid={
                          validation.touched.city &&
                          validation.errors.city
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      State
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="state"
                        id="horizontal-firstname-Input"
                        placeholder="Enter state "
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.state || ""}
                        invalid={
                          validation.touched.state &&
                          validation.errors.state
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-3 col-form-label"
                    >
                      Zip
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="text"
                        className="form-control"
                        name="zip"
                        id="horizontal-firstname-Input"
                        placeholder="Enter postal zip "
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.zip || ""}
                        invalid={
                          validation.touched.zip &&
                          validation.errors.zip
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4 me-3">
                    <div className="modal-footer"> 
                        
                        <Button type="submit" color="primary" className="btn btn-primary me-2">Save</Button>
                        <Button type="button" color="secondary" className="btn btn-secondary me-2" onClick={resetForm}>Cancel</Button>
                    </div>
                  </Row>
                  </Form>
            </LoadingOverlay>
          </CardBody>
        </Card>
      </QdContainer>
    </React.Fragment>
  )
}

export default MasterLocation
