import QdContainer from "components/Common/QdContainer"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import MappingEditForm from "./MappingEditForm"
import OpenDockTemplateConfigurationForm from "./OpenDockTemplateConfigurationForm"

const OpenDockLocMapping = () => {
  //const { locationId, locationName } = useParams()
  const [locationId, setLocationId] = useState()
  const [locationName, setLocationName] = useState()
  const [passedSteps, setPassedSteps] = useState([1])
  const [activeTab, setactiveTab] = useState(1)
  const [emailLocMap, setEmailLocMap] = useState()
  const location = useLocation()
  const { state } = location || {}
  const [m_loc_id, setMlocId] = useState()
  const [loading, setLoading] = useState(false)
  const [showTemplateConfiguration, setShowTemplateConfiguration] =
    useState(false)

  useEffect(() => {
    setLoading(true)
    const { state } = location || {}
    const m_loc_id = state?.m_loc_id
    setMlocId(m_loc_id)
    console.log(state)
    setLocationId(state?.locationId)
    setLocationName(state?.locName)
    setLoading(false)
  }, [])

  const navigate = useNavigate()

  const onEmailLocMapSuccess = data => {
    setEmailLocMap(data)
  }

  const close = () => {
    navigate("/location-management")
  }

  const showConfiguration = () => {
    setShowTemplateConfiguration(true)
  }

  return (
    <QdContainer>
      <Card>
        <CardBody>
          {!loading && (
            <div>
              {!showTemplateConfiguration && locationId && locationName && (
                <MappingEditForm
                  locationId={locationId}
                  locationName={locationName}
                  mLocId={m_loc_id}
                  showConfiguration={showConfiguration}
                  onEmailLocMapSuccess={onEmailLocMapSuccess}
                />
              )}

              {showTemplateConfiguration && (
                <OpenDockTemplateConfigurationForm
                  qdLocationId={locationId}
                  mLocId={m_loc_id}
                  cancel={close}
                />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </QdContainer>
  )
}

export default OpenDockLocMapping
