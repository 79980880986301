import React, { useContext, useEffect, useState } from "react"
import CredContext from "./Creds"
import ApptSysCards from "./ApptSysCards"
import Header from "./Header"
import CredForm from "./CredForm"

function CredList() {
  const { fetchCreds, loading } = useContext(CredContext)
  const [createNew, setCreatenew] = useState(false)

  useEffect(() => {
    fetchCreds()
    
  }, [])

  const toggleCreateForm = () => {
    setCreatenew(!createNew)
  }

  return (
    <>
     <Header onCreate={toggleCreateForm}/>
      {loading && !createNew &&<div>Loading...</div>}
      {!loading && !createNew && <ApptSysCards />}
      {!loading && createNew && <CredForm isEdit={false} onCancel={toggleCreateForm}/>}
    </>
  )
}

export default CredList
