import React, { useEffect, useState } from 'react'
import { Tbody, Thead } from 'react-super-responsive-table'
import { Card, CardBody, Col, Label, Row, Table } from 'reactstrap'
import EmailRuleEditForm from './EmailRuleEditForm'

const EmailRule = (props) => {
    const [emailGrpRuleBased, setEmailGrpRuleBased] = useState(props.data.email_grp_rule_based || false)
    const [emailRule, setEmailRule] = useState(props.data.email_rule || '')
    const [emailGroups, setEmailGroups] = useState(props.data.email_groups)
    const [isEdit, setIsEdit] = useState(props.isEdit || false)
    const [convertedEmailGroups, setConvertedEmailGroups] = useState([])

    useEffect(() => {
        if (emailGrpRuleBased) {
            const result = Object.entries(emailGroups).map(([groupName, values]) => ({
                groupName,
                ...values
              }));
              setConvertedEmailGroups(result)
        }

        console.log(convertedEmailGroups)
    }, [])

    const togEdit = () => {
        setIsEdit(!isEdit)
    }

    const reloadStagedData = () => {
        console.log('reloading from email rule page')
        props.reload()
        togEdit()
    }

    return (
        <Card outline color="primary" className="border p-0">
            {!isEdit && <CardBody>
                <Row>
                    <div className='text-end'> <i className="mdi mdi-pencil font-size-16 text-primary me-1 pointer" onClick={togEdit} />{" "}</div>
                </Row>
                <Row>
                    <Col md={2}>
                        <Label className='text-muted'>Email:</Label>
                    </Col>
                    <Col md={6}>
                        {emailGrpRuleBased ? 'Rule Based' : 'Static'}
                    </Col>
                </Row>
                <div className="border-bottom mb-2"></div>
                {emailGrpRuleBased && <Row>
                    <Col md={2}>
                        <Label className='text-muted'>Rule Expression:</Label>
                    </Col>
                    <Col md={6}>
                       {props.data?.email_rule}
                    </Col>
                </Row>}
                {emailGrpRuleBased && convertedEmailGroups.length > 0 && <Row>
                    <Col md={2}>
                        <Label className='text-muted'>Email List:</Label>
                    </Col>
                    <Col>
                        <Table>
                            <Thead>
                                <tr>
                                    <th style={{ border: 'none' }}>Group Name</th>
                                    <th style={{ border: 'none' }}>To List</th>
                                    <th style={{ border: 'none' }}>CC List</th>
                                </tr>
                            </Thead>
                            <Tbody>
                               {convertedEmailGroups.map((item, index) => {
                                return <tr key={index}>
                                    <td style={{ border: 'none' }}>{item.groupName}</td>
                                    <td style={{ border: 'none' }}>{item.to}</td>
                                    <td style={{ border: 'none' }}>{item.cc}</td>
                                </tr>
                               })} 
                            </Tbody>
                        </Table>
                    </Col>

                </Row>}
                {!emailGrpRuleBased &&
                    <>
                        <Row>
                            <Col md={2}>
                                To List:
                            </Col>
                            <Col md={6}>
                                {props.data.to}
                            </Col>
                        </Row>
                        <div className="border-bottom mb-2"></div>
                        <Row>
                            <Col md={2}>
                                Cc List:
                            </Col>
                            <Col md={6}>
                                {props.data.cc}
                            </Col>
                        </Row>
                    </>
                }
            </CardBody>}
            {isEdit && <CardBody><EmailRuleEditForm cancelEdit={togEdit} emailGroup={convertedEmailGroups} stagedData={props.data} mLocId={props.mLocId} reloadStagedData={reloadStagedData}/></CardBody>}
        </Card>
    )

}

export default EmailRule