import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, FormFeedback, Input, Label } from 'reactstrap'
import * as Yup from "yup"

const validation_types = [
    { label: "Starts With", value: "starts_with" },
    { label: "Must Not Start With", value: "must_not_start_with" },
    { label: "Mandatory", value: "mandatory" },
    { label: 'Expression Match', value: 'expr_match' }
]

const ValidationForm = (props) => {

    const [valData, setValData] = useState(props.valData || null)
    const [variables, setVariables] = useState(props.vars || [null])
    const [vars, setVars] = useState()

    useEffect(() => {
        debugger
        console.log(valData)
        if (!valData) {
            validation.resetForm()
        }

        var variables = [];
        variables.push({
            label: 'None',
            value: null
        })
        if (props.vars) {


            props.vars.map((item, index) => {
                variables.push({
                    label: item.name,
                    value: item.name
                })
            })


        }

        setVars(variables)

    }, [])



    const closeMe = () => {
        props.closeModal()
    }

    const handleTypeChange = (event) => {
        const { name, value } = event.target;
        var newVal = { ...valData, 'valdn_type': value }
        setValData(newVal)

    }

    const handleVarChange = (event) => {
        const { name, value } = event.target;
        var newVal = { ...valData, 'var': value }
        setValData(newVal)
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {

            name: valData?.name || '',
            var: valData?.var || '',
            valdn_type: valData?.valdn_type || 'starts_with',
            value: valData?.value || '',
            message: valData?.message || ''

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter name of validation"),
            message: Yup.string().required("Please enter message")
        }),
        onSubmit: values => {
            console.log(values.name)
            var val = {
                'name': values.name,
                'var': values.var,
                'valdn_type': values.valdn_type,
                'message': values.message,
                'value': values.value
            }
            props.onSave(val)
        },

    })

    return (
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Add New Validation</h5>
            </div>
            <Form className="form-horizontal" id="addValForm"

            >

                <div className="modal-body">
                    <div className="mb-3">
                        <Label className="form-label">Validation Type</Label>

                        <select className="form-control" name="valdn_type"
                            defaultValue={"mandatory"}
                            onChange={e => {
                                e.preventDefault()
                                handleTypeChange(e)
                            }}
                            value={validation.values.valdn_type}

                        >
                            {validation_types.map((item, index) => (
                                <option value={item.value} key={index}>{item.label}</option>
                            ))}

                        </select>




                        {validation.touched.valdn_type && validation.errors.valdn_type ? (
                            <FormFeedback type="invalid">
                                {validation.errors.valdn_type}
                            </FormFeedback>
                        ) : null}

                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                            id="valId"
                            name="name"
                            className="form-control"
                            placeholder="Enter name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}

                        />


                    </div>



                    {valData?.valdn_type !== 'expr_match' && <div className="mb-3">
                        <Label className="form-label">Variable</Label>
                        {/* <select className="form-control" name="valdn_type"
                                                                onChange={e => {
                                                                    e.preventDefault()
                                                                    handleVarChange(e)
                                                                }}
                                                                value={validation.values.var}

                                                            >
                                                                {vars?.map((item, index) => (
                                                                    
                                                                    <option value={item.value} key={index}>{item.label}</option>
                                                                ))}

                                                            </select> */}

                        <Input
                            id="var"
                            name="var"
                            className="form-control"
                            placeholder="Enter variable"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.var || ""}
                            invalid={
                                validation.touched.var && validation.errors.var
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.var && validation.errors.var ? (
                            <FormFeedback type="invalid">
                                {validation.errors.var}
                            </FormFeedback>
                        ) : null}

                    </div>}

                    {(valData?.valdn_type !== 'mandatory' || validation.values.valdn_type !== 'mandatory') && <div className="mb-3">
                        <Label className="form-label">Value</Label>
                        <Input
                            id="value"
                            name="value"
                            className="form-control"
                            placeholder="Enter value"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.value || ""}
                            invalid={
                                validation.touched.value && validation.errors.value
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.value && validation.errors.value ? (
                            <FormFeedback type="invalid">
                                {validation.errors.value}
                            </FormFeedback>
                        ) : null}

                    </div>}


                    <div className="mb-3">
                        <Label className="form-label">Message</Label>
                        <Input
                            id="message"
                            name="message"
                            className="form-control"
                            placeholder="Enter message"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ""}
                            invalid={
                                validation.touched.message && validation.errors.message
                                    ? true
                                    : false
                            }
                        />
                        {validation.touched.message && validation.errors.message ? (
                            <FormFeedback type="invalid">
                                {validation.errors.message}
                            </FormFeedback>
                        ) : null}

                    </div>


                </div>

                <div className="modal-footer">
                    <button
                        id="addValButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => validation.handleSubmit()}
                    >
                        Save
                    </button>
                    {" "}
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={props.closeModal}
                    >
                        Cancel
                    </button>
                </div>
            </Form>

        </React.Fragment>
    )


}

export default ValidationForm