import QdContainer from 'components/Common/QdContainer'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Form, Input, Label, NavItem, Row, TabContent, TabPane } from 'reactstrap'

import classnames from "classnames"
import EmailAddressMappingForm from './EmailAddressMappingForm'
import TemplateConfigurationForm from './TemplateConfigurationForm'

const EmailLocationMapping = () => {

    //const { locationId, locationName } = useParams()
    const [passedSteps, setPassedSteps] = useState([1])
    const [activeTab, setactiveTab] = useState(1)
    const [emailLocMap, setEmailLocMap] = useState()
    const location = useLocation();
    const { state } = location || {};
    const [mlocId, setMlocId]  = useState()
    const [loading, setLoading] = useState(false)
    const [showTemplateConfiguration, setShowTemplateConfiguration] = useState(false)
    const [locationId, setLocationId] = useState()
    const [locationName, setLocationName] = useState()

    useEffect(() => {
      setLoading(true)
      const m_loc_id = state?.m_loc_id;
      setMlocId(m_loc_id)
      setLocationId(state?.locationId)
      setLocationName(state?.locName)
      setLoading(false)
    }, [])




    const navigate = useNavigate()


    const onEmailLocMapSuccess = (data) => {
        setEmailLocMap(data)
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
          var modifiedSteps = [...passedSteps, tab]
          if (tab >= 1 && tab <= 4) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
          }
        }
      }

    const close = () => {
        navigate('/location-management')
    }

    const showConfiguration = () => {
      setShowTemplateConfiguration(true)
    }

    return (
        <QdContainer>
            <Card>
                <CardBody>
                  {!loading && locationId && locationName &&
                  <div>
                        {!showTemplateConfiguration && <EmailAddressMappingForm locationId={locationId} locationName={locationName} mLocId={mlocId} 
                        showConfiguration={showConfiguration}
                        onEmailLocMapSuccess={onEmailLocMapSuccess}/>}
                        
                         {showTemplateConfiguration && <TemplateConfigurationForm qdLocationId={locationId}  mLocId={mLocId} cancel={close}/>}
                       
                    </div>
}
                </CardBody>
              </Card>
        </QdContainer>

    )
}

export default EmailLocationMapping