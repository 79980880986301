import QdContainer from 'components/Common/QdContainer'
import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import TemplateCard from './TemplateCard'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const ApptReqEmailTemplate = () => {

    const [templates, setTemplates] = useState(null)
    const [loading, setLoading] = useState(false)

    

    const api_url = process.env.REACT_APP_API_URL

    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const detailsView = (template) => {
        navigate("/email-template/view/"+template.name)
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const token = localStorage.getItem("idToken")
            const headers = { Authorization: `Bearer ${token}` }

            var url = api_url

            url = url + "email/templates"

            const response = await fetch(url, { headers })

            if (!response.ok) {
                if ([401, 403].includes(response.status)) {
                    logout()
                }
                throw new Error("Request failed")
            }

            const jsonData = await response.json()
            setTemplates(jsonData)
            setLoading(false)
        } catch (error) {
            console.error("Error:" + error)
            setLoading(false)
            console.error(error)
        }
    }

    const addUser = () => {
        navigate("/create-email-template")
      }

    return (
        <QdContainer>
            <Card>
            <CardBody>
              <Col>
                <div className="float-start">
                  <h5 className="text-primary">Email Templates</h5>
                </div>
              </Col>
              <Col>
                <div className="float-end">
                  <div className="d-flex gap-2">
                    <div className="text-sm">
                    <span style={{ marginRight: "20px" }}>
                        <Link to="/create-email-template" onClick={addUser}>
                          <i className="mdi mdi-email-plus-outline " style={{fontSize: 'large'}} />
                        </Link>
                      </span>

                      <span
                        onClick={() => {
                          fetchData()
                        }}
                        className="pointer text-primary"
                      >
                        <i className="fas fa-sync-alt" />
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </CardBody>
          </Card>
                {loading && <div>Loading....</div>}
                {!loading && templates && templates.length > 0 && (
                    <Row className="mt-1">
                    {
                        
                        templates.map((template, index) => (
                        <TemplateCard
                            key={index}
                            template={template}
                            re={fetchData}
                            details={() => {detailsView(template)}}
                            
                        />
                        ))}
                    </Row>
                )}
           
        </QdContainer>
    )
}

export default ApptReqEmailTemplate