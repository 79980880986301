import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, CardText, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import OpenDockBaseConfig from "./OpenDockBaseConfig"
import OpenDockTenantConfig from "./OpenDockTenantConfig"
import classnames from "classnames";
import classes from './OpenDockLocationConfig.scss'
import OpenDockFinalConfig from "./OpenDockFinalConfig"

const OpenDockLocationConfig = () => {
  const { locationId, locationName } = useParams()
  const [customActiveTab, setcustomActiveTab] = useState("1");

  useEffect(() => {
  }, [])

  const navigate = useNavigate()

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const goBack = () => {
    navigate("/loc-config-open-dock")
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Col>
                <div className="float-start">
                  <h6 className="text-primary">
                    {"Location Configuration - Open Dock (" + locationName + ")"}
                  </h6>
                </div>
              </Col>
              <Col>
                <div className="float-end">
                <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={goBack}
                    >
                      <i className="bx bx-left-arrow-alt font-size-14 align-middle me-2"></i>{" "}
                      Back
                    </button>
                </div>
              </Col>

            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Base Configuration</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Tenant Configuration</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Final Configuration</span>
                  </NavLink>
                </NavItem>

              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <OpenDockBaseConfig locationId={locationId} allowEdit={false} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <OpenDockTenantConfig locationId={locationId} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <OpenDockFinalConfig locationId={locationId} allowEdit={false} />
                    </Col>
                  </Row>
                </TabPane>


              </TabContent>
            </CardBody>
          </Card>


        </Container>
      </div>
    </React.Fragment>
  )
}

export default OpenDockLocationConfig
