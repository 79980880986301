import Success from 'pages/NotificationModal/Success'
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardTitle, Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap'
import TemplateValidations from './TemplateValidations'
import TemplateVars from './TemplateVars'

const var_types = [
    { label: "PATH", value: "PATH" },
    { label: "FMT", value: "FMT" },
    { label: "EXPR", value: "EXPR" },
    { label: "LIT", value: "LIT" }
]

const EditForm = (props) => {

    const [formdata, setFormdata] = useState(props.data?.vars || [])
    const [templateName, setTemplateName] = useState()
    const [desc, setDesc] = useState()
    const [subjectHtml, setSubjectHtml] = useState()
    const [contentHtml, setContentHtml] = useState()
    const [loading, setLoading] = useState(false)
    const [validations, setValidations] = useState(props.data?.validations || [])

    const [showSuccessModal, setShowSuccessModal] = useState(false)

    useEffect(() => {
        if (props.isClone) {
            setTemplateName("")
            setDesc("")
        } else {
            setTemplateName(props.data.name)
            setDesc(props.data.desc)
        }


        const body = props.data.content_body
        const sub = props.data.content_subject

        setContentHtml(atob(body))
        setSubjectHtml(atob(sub))

    }, [])


    const togSuccessModal = () => {
        setShowSuccessModal(!showSuccessModal)
    }

    const api_url = process.env.REACT_APP_API_URL

    const navigate = useNavigate()

    const newVar = () => {
        return {
            "name": "",
            "type": "",
            "value": "",
            "desc": ""

        }
    }

    const pushNewVar = () => {
        var existingVars = [...formdata]
        existingVars.push(newVar())
        setFormdata(existingVars)
    }

    const handleVarChange = (event, index) => {
        const { name, value } = event.target;
        const updatedFormData = [...formdata];
        updatedFormData[index] = { ...updatedFormData[index], 'value': value, required: value.trim() === '' };
        setFormdata(updatedFormData);
    }

    const handleVarNameChange = (event, index) => {
        const { name, value } = event.target;
        const updatedFormData = [...formdata];
        updatedFormData[index] = { ...updatedFormData[index], 'name': value, required: value.trim() === '' };
        setFormdata(updatedFormData);
    }

    const handleTemplateNameChange = (event) => {
        const { name, value } = event.target;
        setTemplateName(value)

    }

    const handleDescChange = (event) => {
        const { name, value } = event.target
        setDesc(value)
    }

    const handleVarDescChange = (event, index) => {
        const { name, value } = event.target;
        const updatedFormData = [...formdata];
        updatedFormData[index] = { ...updatedFormData[index], 'desc': value };
        setFormdata(updatedFormData);
    }

    const handleTypeChange = (event, index) => {
        const { name, value } = event.target;
        const updatedFormData = [...formdata];
        updatedFormData[index] = { ...updatedFormData[index], 'type': value };
        setFormdata(updatedFormData);
    }

    const close = () => {
        setShowSuccessModal(false)
        navigate("/email-templates")
    }

    const handleSubmit = async () => {
        console.log("saving")
        const encoder = new TextEncoder();
        const uint8ArraySubject = encoder.encode(subjectHtml);
        const base64StringSubject = btoa(String.fromCharCode(...uint8ArraySubject));

        const uint8ArrayContent = encoder.encode(contentHtml);
        const base64StringContent = btoa(String.fromCharCode(...uint8ArrayContent));


        var obj = {
            "name": templateName,
            "desc": desc,
            "content_body": base64StringContent,
            "content_subject": base64StringSubject,
            "vars": formdata,
            "validations": validations

        }

        try {
            setLoading(true)

            const token = localStorage.getItem("idToken")

            const config = {
                method: props.isClone ? "POST" : "PUT",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),

            }

            var url = api_url + "email/templates/"
            if (!props.isClone) {
                url = url + templateName
            }
            const response = await fetch(url, config)


            if (!response.ok) {
                throw new Error("Request failed")
            }
            try {
                const jsonData = await response.json()

            } catch (error) {
                console.error(error)
                setLoading(false)
            }
            setShowSuccessModal(true)
            setLoading(false)
            console.log("Saved")

        } catch (error) {
            console.error("Error:", error)
            setLoading(false)
        }
    }

    const removeVar = (index) => {
        const newItems = [...formdata];
        newItems.splice(index, 1);
        setFormdata(newItems)
    }

    const handleCancel = () => {
        props.cancel()
    }

    return (
        <>
            <Modal
                isOpen={showSuccessModal}
                toggle={() => {
                    togSuccessModal()
                }}
                centered
                size="md"
            >
                <Success message='Template configuration saved successfully !!' close={close} />
            </Modal>
            <Row>
                <LoadingOverlay active={loading} spinner>
                    <Card>
                        <CardBody>
                            {props.isClone && <CardTitle className="mb-4 text-primary">Create New Email Template</CardTitle>}
                            {!props.isClone && <CardTitle className="mb-4 text-primary">Update New Email Template</CardTitle>}
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    handleSubmit()
                                    return false
                                }}
                            >
                                <Card outline color="primary" className='border mt-3 mb-3'>
                                    <CardBody>
                                        <Row className="mb-4">
                                            <Label
                                                htmlFor="horizontal-firstname-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Name
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id="horizontal-firstname-Input"
                                                    placeholder="Enter name of template"
                                                    onChange={e => {
                                                        e.preventDefault()
                                                        handleTemplateNameChange(e)
                                                    }}
                                                    value={templateName}
                                                    required

                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Label
                                                htmlFor="desc"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Description
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    name="desc"
                                                    id="horizontal-firstname-Input"
                                                    placeholder="Enter description of template"
                                                    onChange={handleDescChange}
                                                    value={desc}

                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <TemplateVars  vars = {formdata} setVars={setFormdata} isNew={false} isEdit={true} />
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card outline color="primary" className='border mt-3 mb-3'>
                                    <CardBody>
                                        <Row>
                                            <TemplateValidations setValidations={setValidations} validations={validations} isEdit={true} />
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card outline color="primary" className='border mt-3 mb-3'>
                                    <CardBody>
                                <Row className="mb-4">
                                    <Label
                                        htmlFor="desc"
                                        className="col-sm-2 col-form-label"
                                    >
                                        Subject Content
                                    </Label>
                                    <Col sm={6}>
                                        <Input
                                            value={subjectHtml}
                                            type="text"
                                            id="textarea"
                                            // style={{ height: '100px' }}
                                            onChange={e => {
                                                setSubjectHtml(e.target.value);
                                            }}
                                            placeholder="This textarea has a limit of 225 chars."
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">

                                    <Label
                                        htmlFor="desc"
                                        className="col-sm-2 col-form-label"
                                    >Body content</Label>
                                    <Col sm={6}>
                                        <Input
                                            value={contentHtml}
                                            type="textarea"
                                            id="textarea"
                                            style={{ height: '400px' }}
                                            onChange={e => {
                                                setContentHtml(e.target.value);
                                            }}
                                            placeholder="This textarea has a limit of 225 chars."
                                        />
                                    </Col>
                                </Row>
                                </CardBody>
                                </Card>

                                <div className='modal-footer'>
                                    <Button color="primary" className='btn btn-primary me-1' type="submit">Save</Button>
                                    <Button color="secondary" className='btn btn-secondary' type="button" onClick={handleCancel}>Cancel</Button>
                                </div>
                            </Form>


                        </CardBody>

                    </Card>
                </LoadingOverlay>

            </Row>
        </>
    )
}

export default EditForm