import React, { useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const Cards = (props) => {

    const setCard = (card) => {
        props.selectCard(card, props.sectionName)
    }

    return (
        <>
            <div className='d-flex mt-1'>
                {/* <Row className='mt-1'> */}
                {props.data.map((item, index) => (
                    <Col lg={3} key={index} className='p-2 pointer'>
                        <Card onClick={() => setCard(item)} className={"bg-white glow-card pt-1 pb-1 "}>
                            <CardBody className="pt-1 pb-1">
                                <Row>
                                    <Col>
                                        <div className={"mt-2 d-flex justify-content-left text-primary "}>
                                            <h5 className={"text-primary"}>{item.card_title}</h5>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='mt-2 text-paragraph'>
                                    {item.desc}
                                </div>
                                {/* <div className='modal-header'>{item.card_title}</div> */}
                            </CardBody>
                        </Card>
                    </Col>

                ))}
                {/* </Row> */}
            </div>

        </>
    )

}

export default Cards