import React, { useState } from "react"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"

const AdvancedFilter = props => {
  const [searchKeyword, setSearchKeyword] = useState("")
  const [status, setStatus] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  const search = () => {
    var obj = {
      searchText: searchKeyword,
      status: status,
      fromDate: fromDate,
      toDate: toDate,
    }
    props.search({ obj })
  }

  const clearSearch = () => {
    props.clearSearch()
  }

  return (
    <Card>
      <CardBody>
        <Row style={{ alignItems: "center" }}>
          <Col lg={4}>
            <div className="mb-3">
              <Label htmlFor="formrow-email-Input">Search Keyword</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-email-Input"
                placeholder="Search keyword"
                onChange={e => setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">Status</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-password-Input"
                placeholder="Enter status"
                onChange={e => setStatus(e.target.value)}
                value={status}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">From Date</Label>

              <Input
                type="date"
                className="form-control"
                placeholder="Select From Date"
                onChange={e => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-3">
              <Label htmlFor="formrow-password-Input">To Date</Label>

              <Input
                type="date"
                className="form-control"
                placeholder="Select To Date"
                onChange={e => setToDate(e.target.value)}
                value={toDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="text-end justify-content-space-between">
              <Button
                type="button"
                color="primary"
                className="btn btn-sm"
                style={{ marginRight: "10px" }}
                id="confirm"
                onClick={search}
              >
                Search
              </Button>
              <Button
                type="button"
                className="btn btn-sm btn-secondary"
                id="cancel"
                onClick={clearSearch}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default AdvancedFilter
