import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useSticky,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  Modal,
  Form,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import { Link } from "react-router-dom"
import { useState } from "react"
import { subscribe } from "../../common/events"

import moment from "moment"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={10}>
        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Type to search.."}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}

const TenantCustomisationTableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  renderRowSubComponent,
  useSticky,
  refreshTable,
  searchToday,
  searchMonth,
  searchCustom,
  searchWeek,
  setActiveSearch,
  loading,
  getTrProps,
  queryParam,
  totalRows,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const [showFilter, setShowFilter] = useState(false)
  const [expandedrow, setExpandedRow] = useState()

  useEffect(() => {
    subscribe("hideExpandedRow", () => setExpandedRow(null))
  }, [])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleRowClick = row => {
    if (expandedrow === row.id) {
      setExpandedRow(null)
      row.toggleRowExpanded()
    } else {
      setExpandedRow(row.id)
    }
  }

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const refreshMe = () => {
    setExpandedRow(null)
    refreshTable()
  }

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {/* {isGlobalFilter && ( */}
        <Col>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>

        <Col>
          <div className="text-sm-end">
            <span style={{ marginRight: "20px" }}>
              <Link to="#" onClick={toggleFilter}>
                <i className="fa fa-filter" />
              </Link>
            </span>

            <Link to="#" onClick={refreshMe}>
              <i className="fas fa-sync-alt" />
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div>
            <p>Total {totalRows} records found</p>
          </div>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    style={{
                      width: column.width + "px",
                      maxWidth: column.maxWidth + "px!important",
                    }}
                  >
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {showFilter && <Filter column={column} />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loading && <div>Loading....</div>}

            {!loading &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr
                      {...row.getToggleRowExpandedProps()}
                      onClick={() => handleRowClick(row)}
                    >
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            style={cell.column.style}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TenantCustomisationTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TenantCustomisationTableContainer
