import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'


const SuccessPage =() => {
    return (
        <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Card >
                <CardBody >
                    <Row >
                        <Col className="d-flex justify-content-center">
                            <i className="fas fa-check-double font-size-28 text-success"></i>{" "}
                        </Col>
                        
                    </Row>
                    <Row className="">
                        <Col className='font-size-20 text-success d-flex justify-content-center'>
                            Successfully accepted the appointment !!!
                        </Col>
                    </Row>
                   
                </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
    )
}

export default SuccessPage