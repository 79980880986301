import QdContainer from 'components/Common/QdContainer'
import React from 'react'
import IdpForm from './IdpForm'

const CognitoIdp = () => {
    return (
        <QdContainer>
            <IdpForm />
        </QdContainer>
    )
}

export default CognitoIdp