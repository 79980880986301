import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, Card, CardBody, Col, Row } from 'reactstrap'

const TemplateCard = (props) => {

    const navigate = useNavigate()

    const clone = () => {
        navigate("/email-template/clone/" + props.template.name)
    }

    const details = () => {
        props.details()
    }

    

    return (
        <Col lg={3}>
            <Card className={"bg-white glow-card pt-1 pb-1 "} >
                <CardBody className="pt-1 pb-1">
                    <Row>
                        <Col>
                            <div className={"mt-2 d-flex justify-content-left text-primary "}>
                                <h5 className={"text-primary"}>{props.template.name}</h5>
                            </div>
                        </Col>
                        <Col>
                            <div className='mt-2 d-flex justify-content-end'>{props.template.cfgType == 'published' && <Badge color="success" className="mr-1">Published</Badge>}
                                {props.template.cfgType == 'staged' && <Badge color="secondary" className="mr-1">Staged</Badge>}</div>
                        </Col>

                    </Row>
                    <div className='mt-2 text-paragraph'>
                        {props.template.desc}
                    </div>
                    <div className='modal-footer mb-2'>
                        <div className='mt-2 d-flex justify-content-end gap-3'>
                        <i className='fas fa-info pointer text-primary ' onClick={details}/> 
                        <i className='fas fa-copy pointer text-primary ' onClick={clone}/> 
                        </div>
                    </div>
                    
                </CardBody>
            </Card>
        </Col>
    )
}

export default TemplateCard