import React, {useState} from 'react'
import { Container } from 'reactstrap'
import Error from 'pages/AcceptAppointment/Error'
import { useSearchParams } from "react-router-dom"
import LocationMappingForm from './LocationMappingForm'
import SuccessPage from './Success'


const AddLocationMapping = () => {

const [hasError, setHasError] = useState(false)
  const [pathParam, setPathParam] = useSearchParams()
  const [locId, setLocId] = useState(pathParam.get("loc_id"))
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const successResult = () => {
    setSuccess(true)
  }


    return (<React.Fragment>
        {!hasError && !success && (
          <div className="page-content">
            <Container fluid>
              {loading && <div>Loading..</div>}
                <LocationMappingForm locId={locId} successResult={successResult}/>
            </Container>
          </div>
        )}
        {hasError && <Error errorMessage = {errorMessage}/>}
        {success && <SuccessPage />}
      </React.Fragment>)
}

export default AddLocationMapping