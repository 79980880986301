import { useFormik } from 'formik';
import Success from 'pages/NotificationModal/Success';
import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay';
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';

import * as Yup from "yup";

const EditTenantCustomisation = (props) => {

    const [custData, setCustData] = useState(props.data)
    const [loading, setLoading] = useState(false)


    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {

        setCustData(props.data)
        console.log(custData)

    }, [])

    const convertToJSON = (input) => {
        const parts = input.split('='); // Split the input string by '='
        const keys = parts[0].split('.'); // Split the first part by '.' to get keys
        const value = parts[1]; // Value is the second part

        // Construct the nested object
        let obj = {};
        let temp = obj;
        for (let i = 0; i < keys.length - 1; i++) {
            temp[keys[i]] = {};
            temp = temp[keys[i]];
        }
        temp[keys[keys.length - 1]] = value;

        return obj;
    };

    const save = async (data) => {
        try {
            setLoading(true)
            const token = localStorage.getItem("idToken")
            var http_method = 'PUT'
            var config = {}

            if (custData.is_new) {
                http_method = 'POST'
                var config_key_value = custData.xpath + '=' + data.value
                config = convertToJSON(config_key_value)
                console.log('config')
                console.log(config)
            } else {
                var cust_curr_value = custData.current_value
                var xpath_key = custData.xpath
                cust_curr_value[xpath_key] = data.value
                config = cust_curr_value
            }

            data = {
                'customisation_type': custData.customisation_type,
                'config': config
            }

            const http_config = {
                method: http_method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }

            const response = await fetch(api_url + "tenant-customisation", http_config)
            if (!response.ok) {
                throw new Error("Request failed")
            }
            const jsonData = await response.json()
            setLoading(false)
            props.onSuccess()

        } catch (error) {
            console.error("Error:", error)
            setLoading(false)
        }
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            value: custData.is_new ? 
                custData.default_value == 'NONE' ? '' :  custData.default_value 
                : custData.data_type === 'json' ?  JSON.stringify(custData.current_value):custData.current_value[custData.xpath],
        },

        validationSchema: Yup.object({
            value: Yup.string().required("Please enter the value"),
        }),

        onSubmit: (values) => {
            save(values);
        },
    });

    const cancel = () => {
        props.cancel();
    }

    const closeSuccess = () => {
        setShowSuccessModal(true)
        props.refresh()
    }



    return (
        <React.Fragment>

            <div className="modal-header">
                <h5 className="modal-title mt-0">Edit Tenant Customisation</h5>
            </div>
            <LoadingOverlay active={loading} spinner>
                <Form
                    className="form-horizontal p-3"
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className='modal-body'>

                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-4 col-form-label"
                            >
                                Name
                            </Label>
                            <Col sm={8}>
                                <div className='mt-2'>
                                    {custData?.display_name}
                                </div>
                            </Col>
                        </Row>


                        <Row className="mb-4">
                            <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-4 col-form-label"
                            >
                                Value
                            </Label>
                            <Col sm={8} className='mt-2'>
                                {custData?.data_type == 'boolean' &&

                                    <input
                                        type="checkbox"
                                        name="value"
                                        className="form-check-input"
                                        id="customCheck-outlinecolor1"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.value}
                                        checked={validation.values.value}
                                    />
                                }
                                {custData?.data_type === 'string' &&
                                    <Input
                                        name="value"
                                        value={validation.values.value || ""}
                                        type="text"
                                        placeholder="Enter value"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={validation.touched.value &&
                                            validation.errors.value
                                            ? true
                                            : false} />}
                                {custData?.data_type === 'integer' &&
                                    <Input
                                        name="value"
                                        value={validation.values.value || ""}
                                        type="number"
                                        placeholder="Enter value"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={validation.touched.value &&
                                            validation.errors.value
                                            ? true
                                            : false} />}
                                {custData?.data_type === 'json' &&
                                    <span>{value}</span>
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className='modal-footer'>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={cancel}

                        >
                            Cancel
                        </button>
                    </div>
                </Form>
            </LoadingOverlay>
        </React.Fragment>
    )
}

export default EditTenantCustomisation