import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Container } from "reactstrap"
import Error from "./Error"
import StopDetail from "./StopDetail"
import SuccessPage from "./Success"

const AcceptAppointment = () => {
  const [hasError, setHasError] = useState(false)
  const [pathParam, setPathParam] = useSearchParams()
  const [stopId, setStopId] = useState(pathParam.get("stop_id"))
  const [stop, setStop] = useState()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    console.log(pathParam)
    console.log(pathParam.get("stop_id"))
    setStopId(pathParam.get("stop_id"))

    fetchData()
  }, [])

  const successResult = () => {
    setSuccess(true)
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("idToken")
      const headers = { Authorization: `Bearer ${token}` }

      var url = api_url + "stop/" + stopId
      console.log("stop id = " + stopId)
      console.log(url)
      const response = await fetch(url, { headers })
      debugger
      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          logout()
        }
        setHasError(true)
        const res = await response.json()
        setErrorMessage(res.message)
        setLoading(false)
        throw new Error(res.message)
      }

      const jsonData = await response.json()
      console.log(jsonData.data)
      setStop(jsonData.data)
      console.log(stop)
      setLoading(false)
    } catch (error) {
      console.error("Error:" + error)
      setLoading(false)
      console.error(error.message)
    }
  }

  return (
    <React.Fragment>
      {!hasError && !success && (
        <div className="page-content">
          <Container fluid>
            {loading && <div>Loading..</div>}
            {!loading && <StopDetail data={stop} successResult={successResult}/>}
          </Container>
        </div>
      )}
      {hasError && <Error errorMessage = {errorMessage}/>}
      {success && <SuccessPage />}
    </React.Fragment>
  )
}

export default AcceptAppointment
