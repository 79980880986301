import React, { useEffect, useState } from "react"
import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

const ImageModal = props => {
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [image1, setImage1] = useState(null)
  const [image2, setImage2] = useState(null)
  const [customActiveTab, setcustomActiveTab] = useState(1)
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(null)

  const api_url = process.env.REACT_APP_API_URL

  const closeModal = () => {
    props.closeModal()
  }

  

  
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  return (
    <>
      {/* {loading  && <div className="modal-body">Loading...</div>} */}
      {props.refs  && (
        <div className="modal-body">
          <Nav tabs className="nav-tabs-custom nav-justified">
            {props.refs.map((item, index) => {
              return (
                <NavItem key={index}>
                  <NavLink 
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === index + 1,
                    })}
                    onClick={() => {
                      toggleCustom(index + 1)
                    }}
                    
                  >
                    {/* <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span> */}
                    <span className="d-none d-sm-block">{item.name}</span>
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
          <TabContent activeTab={customActiveTab} className="p-3 text-muted">
            {
              props.refs.map((item, index) => {
                return (<TabPane tabId={index+1} key={index}>
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        
                          <img
                            src={`data:image/png;base64,${item.img}`}
                            style={{ width: "100%", height: "100%" }}
                          />
                        
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>)
              })}
          </TabContent>
        </div>
      )}
    </>
  )
}

export default ImageModal
