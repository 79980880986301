import React, { useEffect, useState } from "react"
import { Button, Col, Container, Label, Row } from "reactstrap"
import CreateNew from "./CreateNew"
import ClosableTag from "./ClosableTag"

const ViewNotification = props => {
  const [data, setData] = useState(props.data)

  useEffect(() => {
    setTimeout(() => {
      console.log(props.data)
      setData(props.data)
    }, 2000)
  }, [])

  const showEdit = () => {
    props.showEdit()
  }

  return (
    <React.Fragment>
      {!data && <CreateNew setNew={props.setNewForm} />}
      {data && (
        <>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">TO</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {data.to?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">CC</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {data.cc?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">Default To</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {data.default_to?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Label className="col-form-label">Default CC</Label>
            </Col>
            <Col>
              <div className="d-flex wrapper p-0">
                {data.default_cc?.map((tag, index) => (
                  <ClosableTag
                    showClose={false}
                    key={index}
                    label={tag}
                    onClose={() => removeTag(index)}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary waves-effect waves-light"
                onClick={showEdit}
              >
                Edit
              </Button>
            </div>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default ViewNotification
